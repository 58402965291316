import React, { useState } from 'react';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  IconButton,
  Button,
  Stack
} from '@mui/material';
import Icon from '@mui/material/Icon';
import DeleteIcon from '@mui/icons-material/Delete';

import { useFieldArray, useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../shared/FormInputText';
import { FormInputDropdown } from '../shared/FormInputDropdown';
import _ from 'lodash';
import { Fieldset } from '../shared/Fieldset';
import { useSelector } from 'react-redux';
function FormRowRadio(props) {
  const { control, name, label, options, rules, readonly } = props;
  return (
    <Grid item md={6} xs={10} lg={4}>
      <FormInputDropdown
        name={name}
        control={control}
        label={label}
        options={options}
        readonly={readonly || false}
        rules={rules}
      />
    </Grid>
  );
}

function FormRow(props) {
  const { control, name, label, rules, inputType, readonly } = props;
  return (
    <React.Fragment>
      <Grid item
        md={6}
        xs={10}
        lg={4}
      >
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
          id={props.id}
        />
      </Grid>
    </React.Fragment>
  );
}

const FamilyDetails = ({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setCustomerInfo,
  customerInfo,
  isNextActive
}) => {let emptyFamily = [{
  name: '',
  age: '',
  relationship: '',
  income: '',
  loan_company: '',
  gender: '',
  address: '',
  occupation: '',
  contact_no: '',
  aadharId: 0
}]
 
  const { handleSubmit, control, reset, formState: { }, getFieldState, getValues } = useForm({
    defaultValues: { familyDetails:_.size(customerInfo.familyDetails)>0?customerInfo.familyDetails:[{
      "name": "",
      "age": null,
      "relationship": "",
      "income": null,
      "gender": "",
      "address": "",
      "occupation": "",
      "mobileNumber": ""

    }] }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "familyDetails",
  });
  
  
  const globalConfiguration = useSelector(
    (state) => state?.globalConfiguration?.data
  );
  const onSubmit = (data) => {
   
    setCustomerInfo({
      ...customerInfo, familyDetails: data.familyDetails,
      references: data?.references
    })
    handleNext();
  }
 
  const handleAdd = (e, type) => {
    if (type === "family") {
      let a = _.get(customerInfo, 'familyDetails[0].id') ? [...customerInfo.familyDetails, ..._.get(getValues(), 'familyDetails', []), emptyFamily[0]] : [..._.get(getValues(), 'familyDetails', []), emptyFamily[0]]
      setCustomerInfo({
        ...customerInfo,
        familyDetails: [...a
        ]
      })
      e.preventDefault();
    } else {
      let a = _.get(customerInfo, 'references[0].id') ? [...customerInfo.references, ..._.get(getValues(), 'references', []), emptyFamily[0]] : [..._.get(getValues(), 'references', []), emptyFamily[0]]
      setCustomerInfo({
        ...customerInfo,
        references: [...a
        ]
      })
      e.preventDefault();
    }



  }

  const handleRemove = (index, type) => {
    if (type === "family") {
      let details = customerInfo.familyDetails
      details.splice(index, 1);
      setCustomerInfo({
        ...customerInfo,
        familyDetails: [...details]
      })
    } else {
      let details = customerInfo?.references
      details.splice(index, 1);
      setCustomerInfo({
        ...customerInfo,
        references: [...details]
      })
    }

  }

  React.useEffect(() => {
    reset(customerInfo)
  }, [customerInfo])
  
  let customerInfoDetails = _.size(_.get(customerInfo, 'familyDetails')) > 0 ? [...customerInfo.familyDetails] : [{ id: 1 }]
  
  const renderCommonList = (type) =>{

    if(type=="relationshipList" || type=="occupation"){
      let list = _.map(globalConfiguration[type], (value, key) => ({ value: key, label: value }));
      return list
    }else{

      let list = _.map(globalConfiguration?.customerOptions[type], (value, key) => ({ value: key, label: value }));
      
      
    return list
    }
    
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <Card>
       
        <Box sx={{margin:"24px"}}>
        <Fieldset
            margin="0px"
            // marginTop="25px"
            title={
              <Stack direction="row" alignItems="center" gap={1}>
                Family Details
              </Stack>
            }
            color="#4984BF"
            titleSize="1.2rem"
            borderWidth={1.5}
            borderRadius={1}
          >
          <Box sx={{display:'flex',alignItems:'flex-start'}}>
          <Grid container spacing={2} margin={"3px"}>
         {fields.map((item, index) => {
          console.log('customesdsdrInfo.familyDetails',`familyDetails[${index}].gender`)
          
          return <>
            <CardContent key={index} id={"card" + index} className="family-card">
              <Grid container spacing={2} >
              <Grid container  item spacing={2} mb={1} >
                  <FormRow control={control} name={`familyDetails.${index}.name`} label="Name" rules={CustomerRules.firstName} readonly={isNextActive} />
                  <FormRow control={control} name={`familyDetails.${index}.age`} label="Age" inputType="number" rules={CustomerRules.age} readonly={isNextActive} />
                  <Grid item md={6} xs={10} lg={4}>
                  <FormInputDropdown control={control} name={`familyDetails.${index}.gender`}  label="Gender" options={
                   [
                      {
                        "value": "male",
                        "label": "Male"
                      },
                      {
                        "value": "female",
                        "label": "Female"
                      },
                      {
                        "value": "transgender",
                        "label": "Transgender"
                      }
                    ]
                  } rules={CustomerRules.gender} />
                  
                </Grid>
                    {/* <FormRowRadio
                      name={`familyDetails[${index}].gender`}
                      control={control}
                      label="Gender"
                      options={renderCommonList("gender")}
                      rules={CustomerRules.gender}
                      // readonly={isNextActive}
                    /> */}
                  
                  
                </Grid>
                <Grid container  item spacing={2} mb={1} >
                 
                  <FormRow control={control} name={`familyDetails.${index}.address`} label="Address" rules={CustomerRules.address1} readonly={isNextActive} />
                 
                    <FormRowRadio
                      name={`familyDetails.${index}.relationship`}
                      control={control}
                      label="Relationship"
                      options={renderCommonList("relationshipList")}
                      rules={CustomerRules.guarantor_relationship}
                      readonly={isNextActive}
                    />
                   
                   
                  <FormRowRadio control={control} name={`familyDetails.${index}.occupation`} label="Occupation"   options={renderCommonList("occupation")} rules={CustomerRules.occupation} readonly={isNextActive} /> 
                  
                </Grid>
                
                <Grid container  item spacing={2} mb={1}  >
                  <FormRow control={control} inputType="number" name={`familyDetails.${index}.income`} label="Income" rules={{ required: CustomerRules.commonValidation("Income") }}  readonly={isNextActive} />
                  <FormRow control={control} inputType="number" name={`familyDetails.${index}.mobileNumber`} label="Mobile Number" rules={CustomerRules.contact_no} readonly={isNextActive} />
                </Grid>
                
               
              </Grid>
              {_.size(customerInfoDetails) > 1 &&
              <Button variant='outlined' sx={{marginTop:"20px"}} startIcon={ <DeleteIcon />} onClick={() => handleRemove(index, 'family')}>
                Delete
              </Button>
                }
                {index+1==_.size(customerInfoDetails) && <div style={{display:'flex',justifyContent:'center'}}>
                  <Button variant='outlined' sx={{marginTop:"20px"}} startIcon={ 
                  <Icon baseClassName="fas" className="fa-plus-circle" color="primary"  />
                } onClick={(e) => handleAdd(e, 'family')}>
                Add
              </Button>
                </div> }
            </CardContent>
          </>
          //}
        })}
        </Grid>
        </Box>
        </Fieldset>
        </Box>
        
      
       


        <div className="clear"></div>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
          <Button   variant="contained"
              sx={{ mt: 2, ml: 1 }} onClick={handleBack} >
                Previous
              </Button>
            <Button
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              type="submit"
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
      </Card>
    </form>
  );
};


export default FamilyDetails;
