import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@mui/material';
import {
  // AlertCircle as AlertCircleIcon,
  // BarChart as BarChartIcon,
  // Lock as LockIcon,
  // Settings as SettingsIcon,
  // ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  // UserPlus as UserPlusIcon,
  Users as UsersIcon
} from 'react-feather';
import {jwtDecode} from "jwt-decode";

import NavItem from './NavItem';
import MoneyTwoToneIcon from '@mui/icons-material/MoneyTwoTone';
// import PaymentTwoToneIcon from '@mui/icons-material/PaymentTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import BuildCircleTwoToneIcon from '@mui/icons-material/BuildCircleTwoTone';
import _ from 'lodash';
import { timeClockClasses } from '@mui/x-date-pickers';
// const user = {
//   avatar: '/static/images/avatars/user_avatar.png',
//   jobTitle: 'Super Admin',
//   name: 'Santhosh'
// };
let userDetails = {}
if(localStorage.getItem('token')){
  userDetails= jwtDecode(localStorage.getItem('token'))
}
const items = [
  {
    href: '/app/applicants',
    icon: UsersIcon,
    title: 'Applicants'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Customers'
  },
  // {
  //   href: '/app/co-applicant',
  //   icon: UsersIcon,
  //   title: 'Co-Applicant'
  // },
  {
    href: '/app/loans',
    icon: MoneyTwoToneIcon,
    title: 'Applications'
  },
  {
    href: '/app/my-loans',
    icon: ListAltTwoToneIcon,
    title: 'My Applications'
  },
  {
    href: '/app/users',
    icon: UserIcon,
    title: 'Users'
  },
  {
    title: 'Settings',
    icon: BuildCircleTwoToneIcon,
    children: [
      {
        href: '/app/dataset',
        title: 'Dataset',
        icon: ListAltTwoToneIcon
      },
      {
        href: '/app/role',
        title: 'Role',
        icon: ListAltTwoToneIcon
      }
    ]
  }
  // {
  //   href:'/app/dataset',
  //   icon:BuildCircleTwoToneIcon,
  //   title: 'Settings'
  // }
  // {
  //   href: '/app/emi',
  //   icon: PaymentTwoToneIcon,
  //   title: 'EMI'
  // }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && _.functions(onMobileClose)) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          // src='/static/user.logo.png'
          style={{background:'#779af0'}}
          to="/"
        >  {_.startCase(_.get(userDetails, 'user.name'))[0]}
        
        </Avatar>

        <Typography
          color="textPrimary"
          variant="h5"
        >
          {}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
          style={{fontWeight:'800'}}
        >
          {_.startCase(_.get(userDetails,'user.role'))}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              children={item.children}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden xlDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {  
  },
  openMobile: false
};

export default DashboardSidebar;
