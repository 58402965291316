import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography, Card, CardContent, Box, Stack, Button } from "@mui/material";
import { Collapse, Divider, Image, Modal, notification, Radio, Segmented, Spin, Table, Tabs, theme } from "antd";
import _ from "lodash";
import { useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { services } from "src/components/services/services";
import BreadcrumbsComponent from "src/components/Breadcrumbs";
import "../style.css"
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const ApplicantViewScreen = (props) => {
  const [loading, setloading] = useState(false);
  const globalConfiguration = useSelector((state) => state?.globalConfiguration?.data);
  let { id } = useParams();
  const [customerDetail, setcustomerDetail] = useState({});
  const [imageData, setImageData] = useState(null);
  const navigate = useNavigate();
  const [previewVisible, setPreviewVisible] = useState(false);
  const previewRef = useRef();
  const [imageUrl, setimageUrl] = useState("");
  const [tabKey, setTabKey] = React.useState(1);

  const handleChange = (e) => {
    setTabKey(e);
  };
  useEffect(() => {
    if (id) {
      getCustomerDetail(id);
    }
  }, []);
let sidebarOptions =[{label:'Applicant Details',value:1}, 
       {label:'Personal Info',value:2}, {label:'Address Details',value:3},
       {label:"Family Details",value:4},{label:"Bank",value:5},{label:"Reference Details",value:6},{label:"Document Details",value:7}
       ,{label:"Co-applicant",value:8}]
  const getCustomerDetail = (id) => {
    services
      .getService(`applicant/details/${id}`)
      .then((res) => {
        setcustomerDetail(res?.data);
      })
      .catch((err) => {});
  };

  const ViewImage = async (value) => {
    try {
      let response = await services.getService(`file/get?key=${value}`);
      

      if (response?.presignedUrl) {
        window.open(response?.presignedUrl, "_blank");
        // setimageUrl(response?.presignedUrl)
        // setPreviewVisible(true)
      } else {
        console.error("Missing presignedUrl or key", response);
      }
    } catch (err) {
      let errors = err?.data?.results;
      if (errors) {
      }
      throw err;
    }
  };
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type == "error" ? "Error" : "Success",
      description: type == "error" ? "Something went wrong" : "Customer created successfully",
      // duration:10
    });
  };
  const onSubmit = (data) => {
    let customer = { ...data };
    customer.familyDetails = _.map(data?.familyDetails, (value) => ({ ...value, age: parseInt(value?.age), income: parseFloat(value?.income) }));
    customer.references = _.map(data?.references, (value) => ({ ...value, age: parseInt(value?.age) }));

    setloading(true);
    services
      .postService(`applicant/add`, customer)
      .then((res) => {
        setloading(false);
        openNotificationWithIcon("success");
        navigate("/app/customers");
      })
      .catch((err) => {
        

        setloading(false);
        openNotificationWithIcon("error");
        if (!localStorage.getItem("token")) {
          openNotificationWithIcon("error");
        }
      });
  };

  const filterLoanProduct = (loanType) => {
    let data = _.map(_.get(globalConfiguration, "loanProducts"), (value, key) => ({ value: key, label: value }));
    let product = _.filter(data, (value, index) => value?.value == loanType);
    return _.size(product) > 0 ? _.get(_.head(product), "label") : "Loan Product";
  };
  const handlePreview = () => {
    setPreviewVisible(true); // Open the modal with the preview
  };

  const handleCancel = () => {
    setPreviewVisible(false); // Close the modal
  };

  const ViewProfileImage = async (value) => {
    if (value) {
      

      try {
        const response = await services.getService(`file/get?key=${value}`);
        if (response?.presignedUrl) {
          return { imageUrl: response.presignedUrl, success: true };
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
    return null;
  };

  // Render Product
  const renderProduct = (data, type) => {
    let details =
      type == "applicant"
        ? ["firstName", "lastName", "aadharId", "mobileNumber", "email", "dob", "gender"]
        : ["spouseName", "fathersName", "mothersName", "education", "maritalStatus", "religion", "caste", "physicallyChallenged", "occupation"];

    return (
      <Box>
        <Grid container spacing={"1px"} padding={"0px"}>
          {_.map(_.pick(customerDetail, details), (value, key) => {
            return (
              (value != undefined || value != null) &&
              value && (
                <Grid item xs={12} key={key} sm={6} md={3} marginBottom={"12px"}>
                  {" "}
                  <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{}}>
                    <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>{_.startCase(key)}</Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>
                      {key == "dob" ? (_.isObject(value) == true ? "Object" : value) : _.startCase(value)}
                    </Typography>
                  </Box>
                </Grid>
              )
            );
          })}
        </Grid>
      </Box>
    );
  };

  useEffect(() => {
    const fetchImageData = async () => {
      if (customerDetail?.photo) {
        const data = await ViewProfileImage(customerDetail?.photo);
        

        setImageData(data);
      }
    };

    fetchImageData();
  }, [customerDetail?.photo]);
  const formatAadhaarNumber = (aadhaarNumber) => {
    // Remove non-numeric characters
    const digits = aadhaarNumber.replace(/\D/g, "");

    // Check if the number is valid (12 digits for Aadhaar)

    // Format the number as XXXX XXXX XXXX
    return `${digits.slice(0, 4)} ${digits.slice(4, 8)} ${digits.slice(8)}`;
  };
  const renderCustomerDetails = (data, type) => {
    let details =
      type == "applicant"
        ? ["firstName", "lastName", "aadharId", "mobileNumber", "email", "dob", "gender"]
        : ["spouseName", "fathersName", "mothersName", "education", "maritalStatus", "religion", "caste", "physicallyChallenged", "occupation"];
    
    // const imageData =customerDetail?.photo ? await ViewProfileImage(customerDetail?.photo):null;
    // 

    return (
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={"1px"} padding={"0px"}>
          {_.map(_.pick(customerDetail, details), (value, key) => {
            return (
              (value != undefined || value != null) &&
              value && (
                <Grid item xs={12} key={key} sm={6} md={3} marginBottom={"12px"}>
                  {" "}
                  <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{}}>
                    <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>{_.startCase(key)}</Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>
                      {key == "dob"
                        ? _.isObject(value) == true
                          ? moment(value).format("YYYY-MM-DD")
                          : moment(value).format("YYYY-MM-DD")
                        : key == "mobileNumber"
                        ? formatIndianPhoneNumber(value)
                        : key == "aadharId"
                        ? formatAadhaarNumber(value)
                        : _.startCase(value)}
                    </Typography>
                  </Box>
                </Grid>
              )
            );
          })}
        </Grid>
        {imageData?.imageUrl && (
          <Box>
            <div style={{ width: "170px", height: "170px", cursor: "pointer" }}>
              <img style={{ width: "100%", height: "100%" }} src={imageData?.imageUrl} />
              {/* <div style={{fontSize:"12px",marginTop:"10px",color:"#6b778c",textAlign:"center"}}>Upload Profile</div> */}
            </div>
          </Box>
        )}
      </Box>
    );
  };

  const renderBank = (data, type) => {
    return (
      <Box>
        <Grid container spacing={"1px"} padding={"0px"}>
          {_.map(data?.bankDetails, (value, key) => {
            return (
              <Grid item xs={12} key={key} sm={6} md={3} marginBottom={"12px"}>
                {" "}
                <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{}}>
                  <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>{_.startCase(key)}</Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>
                    {key == "dob" ? (_.isObject(value) == true ? "Object" : value) : _.startCase(value)}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  //Render Invome and expense
  const renderAddress = (details, type) => {
    let permanent = type == "present" ? details?.addressDetails?.present : details?.addressDetails?.permanent;

    return (
      <Box>
        <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000", textDecorationLine: "underline", marginBottom: "15px" }}>
          {_.startCase(type)}
        </Typography>
        <Grid container spacing={2}>
          {_.map(permanent, (value, key) => {
            return (
              <Grid item xs={12} key={key} sm={6} md={2}>
                {" "}
                <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{ marginRight: "16px", marginBottom: "16px" }}>
                  <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>{_.startCase(key)}</Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>
                    {_.isObject(value) == true ? "Object" : value}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };
  const formatIndianPhoneNumber = (phoneNumber) => {
    // Remove non-numeric characters
    const digits = phoneNumber.replace(/\D/g, "");

    // Format the number as 987-654-3210
    return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`;
  };
  const renderFamily = (details, type) => {
    return _.map(details, (item, index) => {
      return (
        <Box key={index}>
          <Grid container spacing={2}>
            {_.map(item, (value, key) => {
              return (
                <Grid item xs={12} key={key} sm={6} md={2}>
                  {" "}
                  <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{ marginRight: "16px", marginBottom: "16px" }}>
                    <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>{_.startCase(key)}</Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>
                      {key == "Income"
                        ? formatIndianNumber(value)
                        : key == "mobileNumber"
                        ? formatIndianPhoneNumber(value)
                        : _.isObject(value) == true
                        ? "Object"
                        : _.startCase(value)}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          {_.size(details) != index + 1 && <Divider />}
        </Box>
      );
    });
  };

  //render Document
  const renderDocument = (data) => {
    return (
      <>
        {_.map(data?.documents, (item, key, index) => {
          

          return (
            <Grid container key={index} sx={{ display: "flex", flexDirection: "column" }}>
              <Box>
                <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000", textDecorationLine: "underline", marginBottom: "15px" }}>
                  {_.startCase(key)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                {_.map(item, (v, i, index) => {
                  delete v?.documentUpload;

                  return _.map(v, (items, i) => {
                    return (
                      <Grid item key={i} sm={6} md={3}>
                        {" "}
                        <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{ marginRight: "16px", marginBottom: "8px" }}>
                          <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>
                            {i == "url" ? "Attachment" : _.startCase(i)}
                          </Typography>
                          {i == "url" ? (
                            <Typography
                              component={"span"}
                              onClick={() => {
                                ViewImage(items);
                              }}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#00000",
                                boxShadow: "0 4px 8px #0000001a",
                                border: "1px solid #eaecf0",
                                padding: "2px 12px",
                                borderRadius: "7px",
                                cursor: "pointer",
                              }}
                            >
                              View
                            </Typography>
                          ) : (
                            <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>
                              {i == "type" ? _.startCase(items) : items}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    );
                  });
                })}
              </Box>
            </Grid>
          );
        })}
      </>
    );
  };

  function formatIndianNumber(value) {
    if (!_.isNumber(value)) return value;

    const number = _.toString(value);
    const lastThree = number.slice(-3);
    const rest = number.slice(0, -3);

    return rest.length > 0 ? rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree : lastThree;
  }

  function calculateObjectValuesSum(obj) {
    return _.chain(obj)
      .values() // Get all values of the object
      .map((value) => parseFloat(value) || 0) // Convert each value to a float, default to 0 if not a number
      .sum() // Sum all the values
      .value(); // Extract the result
  }

  const getItems =  [
    {
      key: "1",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Applicant Details</span>{" "}
        </div>
      ),
      children: renderCustomerDetails(customerDetail, "applicant"),
//       style: panelStyle,
    },
    {
      key: "2",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Personal Info</span>{" "}
        </div>
      ),
      children: (
        <div>
          <div>{renderProduct(customerDetail)}</div>
        </div>
      ),
//       style: panelStyle,
    },
    {
      key: "3",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Address</span>{" "}
        </div>
      ),
      children: (
        <div>
          {renderAddress(customerDetail, "present")}
          {renderAddress(customerDetail, "Permanent")}
        </div>
      ),
//       style: panelStyle,
    },
    {
      key: "4",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Family Details</span>{" "}
        </div>
      ),
      children: renderFamily(customerDetail?.familyDetails),
//       style: panelStyle,
    },
    {
      key: "5",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Bank</span>{" "}
        </div>
      ),
      children: renderBank(customerDetail),
//       style: panelStyle,
    },
    {
      key: "6",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Reference Details</span>{" "}
        </div>
      ),
      children: renderFamily(customerDetail?.references),
//       style: panelStyle,
    },
    {
      key: "7",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Documents Details</span>{" "}
        </div>
      ),
      children: renderDocument(customerDetail),
//       style: panelStyle,
    },
  ];
  const onChange = (key) => {};
  const { token } = theme.useToken();
//   const panelStyle = {
//     marginBottom: 24,
//     background: token.colorFillAlter,
//     borderRadius: token.borderRadiusLG,
//     border: "none",
//   };

  const convertAdharSpace = (number) => {
    const result = _.chunk(number.toString(), 4)
      .map((chunk) => chunk.join("")) // Join each chunk back into a string
      .join(" ");

    return result;
  };
  let breadCrumbsData = [];

  breadCrumbsData =
    props?.customer_type == "applicant"
      ? [
          { name: `Applicants`, path: "/app/applicants" },
          { name: id ? "Edit" : "Add", path: id ? `/app/applicant/${id}` : "/app/applicant/add" },
        ]
      : [
          { name: `Applicants`, path: "/app/applicants" },
          { name: "View", path: id ? `app/applicant/details/${id}` : "/app/customers/add" },
        ];
        const columns = [
              {
                     title: 'Customer Id',
                     dataIndex: 'customerId',
                     key: 'customerId',
                   },
              {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
              },
              {
                     title: 'Last Name',
                     dataIndex: 'lastName',
                     key: 'lastName',
                   },
              {
                title: 'Mobile Number',
                dataIndex: 'mobileNumber',
                key: 'mobileNumber',
              },
             
            ];
        const renderCoApplicant =()=>{
              
        let sampleList =[
              {
                "_id": "6705a8a3678d47f2224fb0e9",
                "firstName": "Dinesh",
                "lastName": "Ram",
                "customerId": "TCC00000000005",
                "mobileNumber": "9876543270"
              }
            ]
              return _.size(_.get(customerDetail,"coApplicants"))>0?<div>
                     <Table dataSource={sampleList} columns={columns} pagination={false} />
              </div>:
               <div>
<Table dataSource={sampleList} columns={columns}  pagination={false} />
<div style={{marginTop:'10px',display:'flex',alignItems:'center',justifyContent:'center'}}>
<Button
        variant="contained"
        sx={{
          height: "36px",
          background: "linear-gradient(90deg, #0066cc, #0055a5)",
          color: "white",
        }}
       href={`/app/applicant/add?applicant=${customerDetail?._id}`}
      >
        Add Co-applicant
      </Button>
</div>
               </div>
        }
  return (
    <Spin spinning={loading}>
      <div style={{ padding: "24px" }}>
        {<BreadcrumbsComponent data={breadCrumbsData} />}

        <Card
          sx={{
            background: "#fff",
            border: "1px solid #eaecf0",
            borderBottom: "none !important",
            borderRadius: "8px",
            boxShadow: "0 1px 3px 0 #1018281a,0 1px 2px 0 #1018280f",
            marginBottom: "24px !important",
            padding: "0px",
            marginTop: "20px",
          }}
        >
          <CardContent sx={{ padding: "0px" }}>
            <Modal open={previewVisible} footer={null} onCancel={handleCancel} centered>
              <Image
                preview={false}
                src={imageUrl} // Replace with your image URL
                alt="Preview"
              />
            </Modal>
            <Box sx={{ margin: "24px" }}>

           
              <div style={{display:'flex'}}>
                     <div>
                     <Segmented
    vertical
    size="large"
    onChange={(e)=>{
       handleChange(e)
       
    }}
    options={sidebarOptions}
  />
                     </div>
                     <div key={tabKey} style={{width:"100%",marginLeft:"20px"}}>
                            {tabKey==8?renderCoApplicant() : getItems[tabKey-1].children}
                     </div>
              {/* <div style={{width:"50%"}}>
                     <div className="tab-step-button">
                            <span>Applicant Details</span>
                     </div>
                     <div className="tab-step-button">
                            <span>Personal Info</span>
                     </div>
                     <div className="tab-step-button">
                            <span>Address Details</span>
                     </div>
                     <div className="tab-step-button">
                            <span>Family Details</span>
                     </div>
                     <div className="tab-step-button">
                            <span>Bank</span>
                     </div>
                     <div className="tab-step-button">
                            <span>Reference Details</span>
                     </div>
                     <div className="tab-step-button">
                            <span>Document Details</span>
                     </div>
              </div> */}
              <div></div>
              </div>
            {/* <Radio.Group value={tabKey} onChange={handleChange}>
        <Radio.Button value="1">Tab 1</Radio.Button>
        <Radio.Button value="2">Tab 2</Radio.Button>
        <Radio.Button value="3">Tab 3</Radio.Button>
      </Radio.Group>

      <Tabs activeKey={tabKey} onChange={setTabKey}>
        <TabPane tab="Tab 1" key="1">
          Content of Tab 1
        </TabPane>
        <TabPane tab="Tab 2" key="2">
          Content of Tab 2
        </TabPane>
        <TabPane tab="Tab 3" key="3">
          Content of Tab 3
        </TabPane>
      </Tabs> */}
              {/* <Tabs rootClassName="tab-container-style" type="card" tabPosition={"left"} items={getItems(panelStyle)} /> */}
              {/* <Collapse defaultActiveKey={["1","2","3","4"]}  onChange={onChange} items={getItems(panelStyle)}  style={{
        border: "none",
        background: "#fff",
      }} /> */}

              {/* <Box
            sx={{ display: "flex", justifyContent: "space-between", px: 2, pb: 2 }}
          >
            {" "}
            {props?.activeStep !== 0 && (
              <Button variant="contained" onClick={() => props?.handleBack()} sx={{ mt: 2, ml: 1 }}>
                Previous
              </Button>
            )}
            <Button onClick={()=>{
               onSubmit(customerDetail);
            }} variant="contained" sx={{ mt: 2, ml: 1 }} >
              {"Create"}
            </Button>
          </Box> */}
            </Box>
          </CardContent>
        </Card>
      </div>
    </Spin>
  );
};

export default ApplicantViewScreen;
