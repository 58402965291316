import CustomerList from "src/pages/CustomerList"



const CustomerListScreen =() =>{

       return <CustomerList customer_type="customer" />
}


export default CustomerListScreen