import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Card, CardContent, Container } from '@mui/material';
import CustomerListResults from '../components/customer/CustomerListResults';
import CustomerListToolbar from '../components/customer/CustomerListToolbar';
import { services } from 'src/components/services/services';
import { useLocation, useNavigate } from 'react-router-dom';
import SnackBar from 'src/components/shared/SnackBar';
import _ from "lodash";
import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import { setApiData } from 'src/redux/globalConfigurationSlice';
import BreadcrumbsComponent from 'src/components/Breadcrumbs';
// import BreadcrumbsComponent from 'src/components/Breadcrumbs';
const CustomerList = (props) => {
  const location = useLocation();
  const [customers, setCustomers] = useState([]);
  const [customersPage, setCustomersPages] = useState({});
  const [searchedInputs, setSearchedInputs] = useState('');
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const breadCrumbsData =props?.customer_type=="applicant"? [{ name: 'Applicants', path: '/app/applicants' }]
  :props?.customer_type=="coapplicant"? [{ name: 'Co-applicant', path: '/app/co-applicant' }]
  :
  [{ name: 'Customers', path: '/app/customers' }]
  const handleChange = (e) => {
    setSearchedInputs({
      ...searchedInputs, [e.target.name]: e.target.value
    });
  }
  function removeEmptyValues(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        delete obj[key];
      }
    });
    return obj;
  }
  const handleSearch = () => {
    setLoading(true)
    let body = removeEmptyValues(searchedInputs)

    if (!_.isEmpty(body)) {
      let apiUrl = props?.customer_type=="applicant"?"applicant/search":"customer/search"
      services.postService(apiUrl, body)
        .then(res => {
          setLoading(false)
          setCustomers(res)

        })
        .catch(err => {

          if (_.get(err, 'data.message[0]')) {
            notification.error({
              message: 'Error',
              description: _.startCase(_.get(err, 'data.message[0]'))
            })
          }
          if (!localStorage.getItem('token')) {
            navigate('/login');
          }
        })
    } else {
      customerList()
    }

  }
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login')
    }
  }, [])
  useEffect(() => {
    getConfiguration()
    customerList()
    
  }, [])
  const getConfiguration = () => {
    services.getService(`configuration/global`)
      .then(res => {
        dispatch(setApiData(res?.data));
       
      })
      .catch(err => {
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      })
  }

  const customerList = (start = 1, count = 10) => {
    let apiUrl = props?.customer_type=="applicant"?"applicant/search":"customer/search"
    services.postService(apiUrl, { page:start,limit:1000 })
      .then(res => {
        console.log("asdasdList,",_.size(_.get(res, 'data')));
        
        setLoading(false)
        setCustomers(_.get(res, 'data'))
        setCustomersPages({ count: _.get(res, 'data.count'), start: _.get(res, 'data.start'), total: _.get(res, 'data.count') })
      })
      .catch(err => {
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      })
  }

  const changePage = (value, count) => {
    customerList(value, count)
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Customers - Thangam Capital </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        {
          location && location.state && location.state.status && <SnackBar open={open} message={location.state.message} handleClose={handleClose} severity="success" />
        }
        <Container maxWidth={false}>
        <BreadcrumbsComponent  data={breadCrumbsData} />
       
          <Card sx={{marginTop:'16px'}}>
          <CardContent>
          <CustomerListToolbar customer_type={props?.customer_type} handleChange={handleChange} handleSearch={handleSearch} resetFields={() => customerList()} />

          <Box sx={{ pt: 3 }}>
            <CustomerListResults customer_type={props?.customer_type} loading={loading} onChangePage={(value, count) => changePage(value, count)} customers={customers} pagination_details={customersPage} />
          </Box>
          </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  )
};

export default CustomerList;
