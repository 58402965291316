import React from 'react';
import ImageUploader from "react-images-upload";
import { Controller } from "react-hook-form";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Upload } from 'antd';
import "./style.css"
// import { Upload } from 'antd';
const { Dragger } = Upload;
export const AntdImageUpload = (props) => {
  const { name, control, label, rules,multiple=false,imageChange="",withPreview=true,uploadText="Click or drag and drop",onUploadDocument,
    disabled
   } = props;
  let fieldRules = rules || {};
  if (rules === undefined) fieldRules.required = false;
  const dummyRequest = ({ file, onSuccess }) => {
       setTimeout(() => {
           onSuccess("ok");
       }, 50);
   };
   const beforeUpload = (file, fileList) => {
       return false
     }
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (
              <Dragger
    disabled={disabled}
                                
              // accept=".jpg,.jpeg,.png"
              className="avatar-uploader"
              showUploadList={true}
              defaultFileList={value}
              onChange={(file)=>{onChange(file);onUploadDocument(file)}}
              beforeUpload={beforeUpload}
              // maxCount={1}
              accept='image/png, image/jpeg, image/jpg'
              multiple={multiple}
              customRequest={dummyRequest}
          >
            <CloudUploadIcon style={{ fontSize: '36px', color: '#EF6820' }} />
            <p style={{ marginTop: '8px' }}>{uploadText}</p>
          </Dragger>
        )}
        rules={{
          ...fieldRules
        }}

      />
    </>
  )
}

export const UserProfileImage = (props) => {
  const { name, control, label, rules,multiple=false,imageChange="",withPreview=true,uploadText="Click or drag and drop",onUploadDocument,
    disabled
   } = props;
   
   
   
  let fieldRules = rules || {};
  if (rules === undefined) fieldRules.required = false;
  const dummyRequest = ({ file, onSuccess }) => {
       setTimeout(() => {
           onSuccess("ok");
       }, 50);
   };
   const beforeUpload = (file, fileList) => {
       return false
     }
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => {
          
          
          
return    <Dragger
disabled={disabled}

              rootClassName='customer-image-upload'              
          // accept=".jpg,.jpeg,.png"
          
          listType='picture-card'
          showUploadList={true}
          
          defaultFileList={value}
          onChange={(file)=>{onChange(file);onUploadDocument(file)}}
          beforeUpload={beforeUpload}
          maxCount={1}
          accept='image/png, image/jpeg, image/jpg'
          multiple={false}
          customRequest={dummyRequest}
      >
        {/* <img  style={{width:"100%",height:"100%"}} src={require('../../assets/images/user-profile-1.png')} />
        <div style={{fontSize:"12px",marginTop:"10px",color:"#6b778c"}}>Upload Profile</div> */}
      </Dragger>
        }
           
        }
        rules={{
          ...fieldRules
        }}

      />
    </>
  )
}



