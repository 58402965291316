import React from "react";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";

export const FormInputDropdown = ({
  name,
  control,
  label,
  options,
  rules = {}, // Default rules to empty object
  readonly,
}) => {
  const generateSingleOptions = () =>
    options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl
          size="small"
          fullWidth
          error={!!error}
          disabled={readonly}
        >
          <InputLabel style={{ fontSize: "1.3rem", lineHeight: "24px" }} shrink>
            {label}
          </InputLabel>
          <Select
            value={value || ""}
            onChange={onChange}
            input={
              <OutlinedInput
                notched
                style={{ lineHeight: "24px" }}
                label={label}
              />
            }
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                fontSize: "1.3rem",
              },
            }}
          >
            {generateSingleOptions()}
          </Select>
          <FormHelperText sx={{ ml: 2 }}>
            {error ? error.message : null}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
