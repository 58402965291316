import React, { useEffect, useState } from 'react';

import {
    Box,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Grid,
    Button,
    Stack,
    Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../shared/FormInputText';
import { Fieldset } from 'src/components/shared/Fieldset';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import _ from 'lodash'
import { useSelector } from 'react-redux';
import { FormInputDropdown } from '../shared/FormInputDropdown';
import CommonApplicantDetails from './common-applicant-details';
import moment from 'moment';
import { FutureDateInput, PastInputDate } from '../shared/FormInputDate';
import { FormAutoCompleteDropdown } from '../shared/FormAutoCompleteDropdown';
function FormRow(props) {
    const { control, name, label, rules, inputType, readonly,setValue } = props;
    return (
        <React.Fragment>
            <Grid item
                md={6}
                xs={10}
                lg={4}
            >
                <FormInputText
                    name={name}
                    control={control}
                    label={label}
                    rules={rules}
                    inputType={inputType || "text"}
                    readonly={readonly || false}
                    helperText={props.helperText || null}
                    // setValue={setValue}
                />
            </Grid>
        </React.Fragment>
    );
}
function FormRowRadio(props) {
    const { control, name, label, options, rules, readonly } = props;
    return (
      <Grid item md={6} xs={10} lg={4}>
        <FormInputDropdown
          name={name}
          control={control}
          label={label}
          options={options}
          readonly={readonly || false}
          rules={rules}
        />
      </Grid>
    );
  }

  function FormRowAutoComplete(props) {
       const { control, name, label, options, rules, readonly } = props;
       return (
         <Grid item md={6} xs={10} lg={4}>
           <FormAutoCompleteDropdown
             name={name}
             control={control}
             label={label}
             options={options}
             readonly={readonly || false}
             rules={rules}
           />
         </Grid>
       );
     }
const LoanDetailsStep = ({
    handleBack,
    handleNext,
    activeStep,
    steps,
    setloanInfo,
    loanInfo,
    isNextActive,
    ApplicantName,
    customerDetail
}) => {
    const globalConfiguration = useSelector(
        (state) => state?.globalConfiguration?.data
      );
    const defaultValuesApplicant = {
        "income.applicant.totalIncome":"",
        "income.applicant.annualIncome":"",
        "income.applicant.otherIncome":"",
        "expenses.applicant.housing":"",
        "expenses.applicant.electricityBill":"",
        "expenses.applicant.education":"",
        "expenses.applicant.healthcare":"",
        "expenses.applicant.debtRepayment":"",
        "expenses.applicant.personal":"",
        "expenses.applicant.businessDevelopment":"",
        "expenses.applicant.transportation":"",
        "expenses.applicant.miscellaneous":"",
        }
            const defaultValuesCoApplicant = {
                "income.coApplicant.totalIncome":"",
                "income.coApplicant.annualIncome":"",
                "income.coApplicant.otherIncome":"",
                "expenses.coApplicant.housing":"",
                "expenses.coApplicant.electricityBill":"",
                "expenses.coApplicant.education":"",
                "expenses.coApplicant.healthcare":"",
                "expenses.coApplicant.debtRepayment":"",
                "expenses.coApplicant.personal":"",
                "expenses.coApplicant.businessDevelopment":"",
                "expenses.coApplicant.transportation":"",
                "expenses.coApplicant.miscellaneous":"",}
    
    const [value, setTabValue] = React.useState(1);
    const { handleSubmit, control,watch,setValue,getValues } = useForm({
        defaultValues:{ ...loanInfo},
    values:{...loanInfo}
    });
     const annualIncome =watch('income.applicant.annualIncome');
  const otherIncome = watch('income.applicant.otherIncome');
    const coApplicantRegularIncome = watch("income.coApplicant.annualIncome",0);
    const coApplicantOtherIncome = watch("income.coApplicant.otherIncome",0);
useEffect(() => {
    calculateTotalIncome(annualIncome, otherIncome,'income.applicant.totalIncome');
}, [annualIncome, otherIncome]);
// Function to check both objects
function validateData(onSubmitData) {
    // Check if all keys from applicant are present and non-empty
    const isApplicantValid = _.every(defaultValuesApplicant, (value, key) => {
        
        
      return _.get(onSubmitData,`${key}`) !== undefined && _.get(onSubmitData,`${key}`) !== "";
    });
  
    // Check if all keys from co-applicant are present and non-empty
    const isCoApplicantValid = _.every(defaultValuesCoApplicant, (value, key) => {
      return _.get(onSubmitData,`${key}`) !== undefined && _.get(onSubmitData,`${key}`) !== "";
    });
  
    // Return true if both applicant and co-applicant are valid, otherwise false
    return {defaultValuesApplicant:isApplicantValid ,
        defaultValuesCoApplicant:isCoApplicantValid}
  }

  const filterLoanProduct = (loanType) => {
    let loanTypes = _.map(
        _.get(globalConfiguration, "loanProducts"),
        (value, key) => ({ value: key, label: value })
      );
    let product = _.filter(
        loanTypes,
      (value, index) => value?.value == loanType
    );
    return _.size(product) > 0
      ? _.get(_.head(product), "label")
      : "Loan Product";
  };

  const loanTenureList = () => {
       let loanTenure = _.map(
           _.get(globalConfiguration, "loanTenure"),
           (value, key) => ({ value: key, label: value })
         );
      
       return loanTenure
     };
    const onSubmit = (data) => {
        
        setloanInfo({
            ...loanInfo, ...data
        })
        handleNext()
        
    }
    const handleChange = (event, newValue) => {
        
        
        setTabValue(newValue);
      };
      
      const calculateTotalIncome = (annualIncome, otherIncome,name) => {
        const regular = parseFloat(annualIncome) || 0;
        const other = parseFloat(otherIncome) || 0;
        const total = regular + other;

        // Set the calculated total income to the totalIncome field
        setValue(name, total.toFixed(2));
    };
     useEffect(() => {
        calculateTotalIncome(coApplicantRegularIncome,coApplicantOtherIncome,'income.coApplicant.totalIncome')
     }, [coApplicantRegularIncome,coApplicantOtherIncome])
     
 
   const getNetIncome = (type) =>{
    
    
    return 4343434
   }
   const filterCoApplicant = (id) => {
    
    if(_.size(_.get(customerDetail,'coApplicants'))>0){
    let coApplicants = _.filter(
        _.get(customerDetail,'coApplicants'),
      (value, index) => value?._id == id
    );
    return {coApplicantName:_.size(coApplicants) > 0
      ? _.startCase(coApplicants[0].firstName+' '+coApplicants[0].lastName)
      : "-",coApplicantId:coApplicants[0]?.customerId,
      coApplicantNumber:coApplicants[0]?.mobileNumber
    };

}else{
    return null
}
  };
    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Card  sx={{
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      // backgroundColor: "#f9f9fc",
      padding: "16px",
    }}>
            <CardContent>
           <CommonApplicantDetails applicantName={ApplicantName}
           applicantNumber={customerDetail?.mobileNumber} applicantId={customerDetail?.customerId}
           LoanName={filterLoanProduct(loanInfo?.loanProduct)}
           loanCode={loanInfo?.loanProduct}
           coApplicant={filterCoApplicant(loanInfo?.coApplicantIdMongo)}  />
     
   {
   <Box key={'tb-2'} padding={2} paddingLeft={'0px'}>
            
            <Fieldset
           
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            Loan Details
          </Stack>
        }
        color="#4984BF"
        titleSize="1.2rem"
        borderWidth={1.5}
        borderRadius={1}
        margin="10px"
        >
        
        <Grid container spacing={2}  sx={{marginLeft:'0px !important',width:"100%",marginTop:"0px !important",padding:"0px !important",}}>
                <Grid
                    container
                    item
                    spacing={1}
                    sx={{marginLeft:'10px !important',width:"100%",marginTop:"16px !important",padding:"0px !important",marginBottom:"16px",
                      // paddingLeft:"20px",
                      marginRight:"20px",
                    }}
                >
                   
                    <FormRow control={control} 
                    inputType="number" 
                    name="loanAmount" label="Loan Amount" 
                    rules={{ required: CustomerRules.commonValidation("Loan Amount") }} 
                     />
                     <FormRowRadio
                name="loanTenure"
                control={control}
                label="Tenure"
                options={loanTenureList()}
                rules={CustomerRules.commonValidation("Loan Tenure")}
              />

               <FormRowAutoComplete
                name="preferredDueDay"
                control={control}
                label="Preferred Due Day"
                options={_.map(_.times(28, Number),(v)=> ({label:v+1,value:v+1}))}
                rules={CustomerRules.commonValidation("Loan Tenure")}
              />
                    
                    
                </Grid>
                <Grid
                    container
                    item
                    spacing={1}
                    sx={{marginLeft:'10px !important',width:"100%",marginTop:"10px !important",padding:"0px !important",marginBottom:"16px",
                      // paddingLeft:"20px",
                      marginRight:"20px",
                    }}
                >
                     <Grid item md={6} xs={10} lg={4}>
                     <FutureDateInput
                            validation={false}
                            control={control}
                            name="expectedDate"
                            label="Expected Date"
                            rules={{
                              required:
                                CustomerRules.commonValidation("Deposit Date"),
                            }}
                            required={true}
                          />
                    
                    </Grid>
                </Grid>
               
                
            </Grid>
        
        </Fieldset>
       
        
        
         </Box>}
      
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft:"24px",paddingRight:"24px" }}>
               
                   
                        <Button
                            variant="contained"
                            sx={{ mt: 2, ml: 1 }}
                            onClick={()=>handleBack()}
                        >
                            Previous
                        </Button>
                   
                     
                        <Button
                            variant="contained"
                            sx={{ mt: 2, ml: 1 }}
                            type="submit"
                        >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
              
                </CardContent>
            </Card>
        </form>
    );
};


export default LoanDetailsStep;
