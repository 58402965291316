import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import {
  Box,
  Grid,
  Container,
  Stack,
  CardContent,
  Button
} from '@mui/material';

import { useForm } from 'react-hook-form';
import { FormInputText } from '../components/shared/FormInputText';
import { services } from 'src/components/services/services';
import SnackBar from 'src/components/shared/SnackBar';
import { isMobile } from 'react-device-detect';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CustomerAccountInfo from 'src/components/customer/CustomerAccountInfo';
import CustomerPersonalInfo from 'src/components/customer/CustomerPersonalInfo';
import IncomeDetailsLoan from 'src/components/loans/IncomeDetailsLoan';
import ProofDetailsLoan from 'src/pages/ProofDetailsLoan';
// import LoanUserDetails from 'src/components/loans/LoanUserDetails';
// import LoanType from 'src/components/loans/loanType';
import _ from 'lodash'
import axios from "axios"
import { Card, notification, Spin, Typography } from 'antd'
import moment from 'moment';
import BreadcrumbsComponent from 'src/components/Breadcrumbs';
import LoanDetailsLoan from '../pages/LoanDetailsLoan'
import LoanType from 'src/components/loans/loanType';
import { useSelector } from 'react-redux';
import AssetDetails from 'src/components/loans/AssetDetails';
import DocumentDetails from 'src/components/loans/loanDocument';
import LoanReviewScreen from 'src/components/loans/loanReview';
import LoanDetailsStep from 'src/components/loans/loanDetails-step';
import { Fieldset } from 'src/components/shared/Fieldset';

const steps = ['Products', 'Income & expenses', 'Assets details', 'Documents',"Loan Details", 'Review'];
const storeDetails  ={
  "applicantIdMongo": "6705a8a3678d47f2224fb0e9",
  "coApplicantIdMongo": "6705a8a3678d47f2224fb0e9",
  "loanProduct": "PL",
  "loanAmount": 500000,
  "loanTenure": 24,
  "preferredDueDay": 5,
  "expectedDate": "01-12-2024",
  "productSpecificDetails": {
    "loanPurpose": "emergency_expenses",
    "employerCategory": "private_organization",
    "employerName": "ABC Private Ltd",
    "designation": "Quality Engineer",
    "experience": 10,
    "annualIncome": 100000,
    "employerEmail": "test@test.com",
    "employerContactNumber": "9743766543",
    "managerName": "Sam A",
    "managerContactNumber": "8976547807",
    "address": "12, ddfdfdfdf, dfdfdfdfd",
    "pincode": "6030776"
  },
  "income": {
    "applicant": {
      "annualIncome": 800000,
      "otherIncome": 200000
    },
    "coApplicant": {
      "annualIncome": 500000,
      "otherIncome": 150000
    }
  },
  "expenses": {
    "applicant": {
      "housing": 20000,
      "food": 15000,
      "electricityBill": 2000,
      "otherUtilities": 1000,
      "education": 5000,
      "healthcare": 3000,
      "debtRepayment": 5000,
      "personal": 7000,
      "businessDevelopment": 10000,
      "transportation": 3000,
      "insurance": 2000,
      "miscellaneous": 1000
    },
    "coApplicant": {
      "housing": 18000,
      "food": 12000,
      "electricityBill": 1500,
      "otherUtilities": 500,
      "education": 4000,
      "healthcare": 2500,
      "debtRepayment": 3000,
      "personal": 6000,
      "businessDevelopment": 8000,
      "transportation": 2500,
      "insurance": 1500,
      "miscellaneous": 700
    }
  },
  "assetDetails": [
    {
      "assetCategory": "movable",
      "assetType": "car",
      "assetValue": 300000,
      "image": ["/image1.jpg"],
      "assetMetadata": {
        "purchaseYear": 2010,
        "manufacturer": "Toyata",
        "model": "Accura 10",
        "vehicleNumber": "TN01 8765",
        "location": "Chennai",
        "ownerName": "Ramanathan"
      }
    }
  ],
  "documents": [
    {
      "type": "salary_slip",
      "uniqueNo": "LD-2023-001",
      "fileLocation": "documents/land_deed.pdf"
    },
    {
      "type": "employee_id_card",
      "uniqueNo": "EB-2023-002",
      "fileLocation": "documents/electricity_bill.pdf"
    }
  ]
}
export const AddEditLoan = () => {
  let { id } = useParams();
  const globalConfiguration = useSelector((state) => state);
  const [searchParams] = useSearchParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isNextActive, setisNextActive] = React.useState(false);
  const [loanInfo, setloanInfo] = React.useState({});


  const loanInitialState = {
    loan_amount: '',
    expected_date: '',
    tenure: '',
    interest_rate: '',
    comments: [],
    field_inspection_comments: '',
    field_inspection_pictures: [],
  };
  const initialErrorState = {
    severity: '',
    message: '',
    error: false
  }

  const [customerDetail, setcustomerDetail] = useState({})
  let [loan, setLoan] = useState(loanInitialState);
  let [apiError, setApiError] = useState(initialErrorState);
  const [isNewLoan] = useState(false);
  // const [isUserAdded, setIsUserAdded] = useState(false);
  const [open, setOpen] = useState(false);
  // const [userDetails, setUserDetails] = useState({});
  const [imageLoading, setImageLoading] = useState(false)
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: { ...loan, interest_rate: 27 },
  });
  
  const history = useNavigate()
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history('/login')
    }
    if (searchParams.get('loan_id')) {
      getLoanView(searchParams.get('loan_id'))
    }
    if(id){
      getCustomerDetail(id)
    }
  }, [])
const getCustomerDetail = (id) => {
  services.getService(`applicant/details/${id}`)
  .then(res => {
setcustomerDetail(res?.data)
  }).catch(err => {

  });

}
  const getLoanView = (id) => {
    services.getService(`loan/details/${id}`)
      .then(res => {
        setValue('loan_amount', parseFloat(_.get(res, 'data.loan_amount')))
        setValue('expected_date', _.get(res, 'data.expected_date'))
        setValue('tenure', parseInt(_.get(res, 'data.tenure')))
        setValue('interest_rate', parseFloat(_.get(res, 'data.interest_rate')))
        setValue('comments', _.get(res, 'data.comments'))
        setValue('field_inspection_comments', _.get(res, 'data.field_inspection_comments'))
        setValue('field_inspection_pictures', _.get(res, 'data.field_inspection_pictures'))
        setValue('loan_products', _.get(res, 'data.loan_products'))
        setLoan({
          loan_amount: parseFloat(_.get(res, 'data.loan_amount')),

        })
      }).catch((error) => {

      })
  }
  const applyNewLoan = async (data) => {
    
    try {
      data.loan_status = "pending_review";
      let response = await services.postService(`loan/add`, data);
      // history('/app/loans')
    
      if (_.get(response, 'status') === true) {
        history(`/app/loan/details/${_.get(response, 'data.loan_id')}?type=loan`)
      }

      setImageLoading(false)
      throw response
    } catch (err) {
      let errors = err && err.data && err.data.results;
      errors && setApiError({
        severity: "error",
        message: `Validation Error :: ${Object.values(errors).flat(2).join(" ")}`,
        error: true
      })
      setImageLoading(false)
      throw err;
    }
  }

  const updateAppliedLoan = async (data) => {
    try {
      let response = await services.putService(`loan/edit/${searchParams.get('loan_id')}`, data)
      if (_.get(response, 'status') === true) {
        history(`app/loan/details/${_.get(response, 'data.loan_id')}?type=loan`)
      }
      setImageLoading(false)
      throw response
    } catch (err) {
      
      setApiError({
        severity: "error",
        message: `Error :: Please try again!`,
        error: true
      })
      setImageLoading(false)
      throw err;

    }
  }
  const onSubmit = (data) => {
    
    if (_.size(_.get(data, 'field_inspection_pictures')) > 0) {
      onProcessImage(_.get(data, 'field_inspection_pictures'), data)
    } else {
      let a = { ...data, customerId: id }
      a.comments = _.size(_.get(data, 'comments', [])) > 0 ? [_.get(data, 'comments')] : []
      a.expected_date = moment(_.get(data, 'expected_date')).format('YYYY-MM-DD')
      a.dueStartDate = moment(_.get(data, 'expected_date')).format('YYYY-MM-DD')
    
      //  setImageLoading(true)
      onFinishData(a)
    }


  };

  const onFinishData = (details) => {
    let final = { ...details }
    if (_.get(details, 'field_inspection_pictures[0]')) {
      final.field_inspection_pictures = _.uniq(_.get(details, 'field_inspection_pictures'))
    }
    if (searchParams.get('loan_id')) {
      updateAppliedLoan(final)
        .then((res) => {

          notification.success({
            message: "Success",
            description: _.get(res, 'message'),
            placement: 'bottomRight'
          })

          // history('/app/loans')
        })
        .catch(err => {
          setImageLoading(false)
          if (_.get(err, 'data.results') && _.size(_.get(err, 'data.results')) > 0) {
            notification.error({
              message: "Error",
              description: _.get(err, `data.results.${Object.keys(_.get(err, 'data.results'))[0]}[0]`),
              placement: 'bottomRight'
            })
          }
          err && setOpen(true)
        })
    } else {
      delete final.password;
      applyNewLoan(final)
        .then((res) => {
          
          notification.success({
            message: "Success",
            description: _.get(res, 'message'),
            placement: 'bottomRight'
          })
          res && setOpen(true)
          // history('/app/loans')
          setImageLoading(false)
        })
        .catch(err => {
          setImageLoading(false)
          if (_.get(err, 'data.results') && _.size(_.get(err, 'data.results')) > 0) {
            notification.error({
              message: "Error",
              description: _.get(err, `data.results.${Object.keys(_.get(err, 'data.results'))[0]}[0]`),
              placement: 'bottomRight'
            })
          }
          err && setOpen(true)
        })
    }
  }

  const handleClose = () => {
    setOpen(false);
    setApiError(initialErrorState)
  };

  const onProcessImage = (data, details) => {
    if (_.size(data) > 0) {
      _.map(data, async (value, index) => {
        if (value) {


          postService(value, `${index}`, details, index + 1)
        }
      })
    } else {
      // log
      let a = { ...details, customerId: id, field_inspection_pictures: [] }
      a.comments = _.size(_.get(details, 'comments', [])) > 0 ? [_.get(details, 'comments')] : []
      a.expected_date = moment(_.get(details, 'expected_date')).format('YYYY-MM-DD')
      a.dueStartDate = moment(_.get(details, 'expected_date')).format('YYYY-MM-DD')
      
      // onFinishData(a)


    }

  }

  const postFiles = async (f, url, k, details, imgIndex) => {

    const formData = new FormData();
    formData.append('file', f);
    axios({
      method: 'put',
      url: url,
      data: f,
      // params:{url:url}
    }).then(async (res) => {
      let getData;
      if (localStorage.getItem('filesData')) {
        getData = [...JSON.parse(localStorage.getItem('filesData')), k]
      } else {
        getData = [k]
      }
      
      await localStorage.setItem('filesData', JSON.stringify(getData))
      if (_.size(_.get(details, 'field_inspection_pictures')) === imgIndex) {
        let a = { ...details, customerId: id, field_inspection_pictures: JSON.parse(localStorage.getItem('filesData')) }
        a.comments = _.size(_.get(details, 'comments', [])) > 0 ? [_.get(details, 'comments')] : []
        a.expected_date = moment(_.get(details, 'expected_date')).format('YYYY-MM-DD')
        a.dueStartDate = moment(_.get(details, 'expected_date')).format('YYYY-MM-DD')
      
        setImageLoading(true)
        onFinishData(a)
      }




    }).catch((err) => {
      

    })



  }

  const postService = async (service, key, details, imgIndex) => {
    
    let split = _.get(service, 'name') ? _.split(_.get(service, 'name'), '.')[1] : _.split(service, '.')[1]
    setImageLoading(true)
    axios({
      method: 'post',
      url: `https://1sjtwuxmcl.execute-api.ap-south-1.amazonaws.com/qa/upload-file`,
      data: { customerId: id, fileName: `${key}.${split}` },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then((res) => {
      let k = `${id}/${key}.${split}`
      postFiles(service, _.get(res, 'data.presignedUrl'), k, details, imgIndex)
      setImageLoading(false)

    }).catch((err) => {
      setImageLoading(false)

    })

  }

  const goToStep = (activeStep) => {
    
    if (activeStep >= 0 && steps.length) {
      setActiveStep(activeStep);
    } else {
    
    }
  }

  const next = () => {
    handleNext(true)
  }

  const previous = () => {
    handleBack(true)
  }

  const handleNext = (val) => {
    setisNextActive(val);
    
    goToStep(activeStep + 1);
  };

  const handleBack = () => {
    goToStep(activeStep - 1);
  };

  function getStepContent(activeStep,ApplicantName) {
    let args = {
      goToStep,
      handleBack,
      handleNext,
      activeStep,
      steps,
      setloanInfo,
      loanInfo,
      id,
      isNextActive
    }

    switch (activeStep) {
      case 0:
        return <LoanType {...args} ApplicantName={ApplicantName} customerDetail={customerDetail} />;
      case 1:
        return <IncomeDetailsLoan {...args} ApplicantName={ApplicantName} customerDetail={customerDetail}/>;
      case 2:
        return <AssetDetails {...args} ApplicantName={ApplicantName} customerDetail={customerDetail}/>;
      
        case 3:
        return <DocumentDetails {...args} ApplicantName={ApplicantName} customerDetail={customerDetail}/>;
        case 4:
        return <LoanDetailsStep {...args} ApplicantName={ApplicantName} customerDetail={customerDetail}/>;
      case 5:
        return <LoanReviewScreen {...args} ApplicantName={ApplicantName} customerDetail={customerDetail}/>;
      default:
        throw new Error('Unknown step');
    }
  }
  const mergeNames = (user) => {
    const { firstName, lastName } = user;
    
    // Create an array of names and compact to remove any undefined values
    const names = _.compact([firstName, lastName]);
    
    // Join the names and convert to startCase
    return _.startCase(names.join(' '));
  }

  const breadCrumbsData = [{ name: 'Customers', path: '/app/customers' }, { name: 'Loan Application', path: `/app/customers/loan/add/${id}` }]

  return (<Spin tip="Loading..." spinning={imageLoading}>

    {
      isNewLoan && <SnackBar open={open} message="test" handleClose={handleClose} severity="success" />
    }
    <Helmet>
      <title>Apply Loan - Thangam Capital </title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        {<BreadcrumbsComponent data={breadCrumbsData} />}
       {_.size(_.get(customerDetail,"coApplicants"))>0 ? <Box sx={{ pt: 3 }}>
          {
            <Stepper activeStep={activeStep} sx={{
              "& .MuiStepLabel-root .Mui-completed": {
                color: "green"
              },
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                color: "orange"
              },
              "& .MuiStepLabel-root .Mui-active": {
                color: "red"
              },
              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                fill: "white"
              },
              pt: 3,
              pb: 5
            }}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel 
                  onClick={() => goToStep(index)}
                  >{label}</StepLabel>
                </Step>

              ))}
              {isMobile && <div><MoreHorizIcon fontSize='large' style={{ color: "#4984BF", fontSize: '44px', marginTop: '4px' }} color='#4984BF' /></div>}
            </Stepper>}
          <React.Fragment>
        
            {getStepContent(activeStep,mergeNames(customerDetail))}
          </React.Fragment>
        </Box>:<Box sx={{marginTop:"40px"}}>
        <Card
    sx={{
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      // backgroundColor: "#f9f9fc",
      padding: "12px",
     
      
    }}
  >
    {/* Applicant Details Section */}
    
        <CardContent>
        <Grid container spacing={2} sx={{marginLeft:'0px',width:"100%",paddingTop:'10px'}}>
        <Fieldset
                title={
                  <Stack direction="row" alignItems="center" gap={1}>
                    Applicant Details
                  </Stack>
                }
                color="#4984BF"
                titleSize="1.2rem"
                borderWidth={1.5}
                borderRadius={1}
                margin="10px"
              >
        <Box display="flex" flexDirection="column"  gap={4} sx={{
          padding:'16px',
          paddingBottom:'10px',
          paddingTop:"26px"
        }}>
   <Box display="flex" flexDirection="row" gap={4} sx={{marginTop:'-8px'}}>
   <Box display="flex" alignItems="flex-start"  flexDirection={'column'}>
        <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
          Applicant Name:
        </Typography>
        <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000",marginTop:"4px" }}>
          Iyyappan
        </Typography>
      </Box>
      {<Box display="flex" alignItems="flex-start" flexDirection={'column'}>
        <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
        Applicant Id:
        </Typography>
        <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000",marginTop:"4px" }}>
        {customerDetail?.customerId?customerDetail?.customerId:customerDetail?.applicantId}
        </Typography>
      </Box>}
   </Box>
 
   <div style={{display:'flex'}}>
   <Button
        variant="contained"
        sx={{
          height: "36px",
          background: "linear-gradient(90deg, #0066cc, #0055a5)",
          color: "white",
        }}
       href={`/app/applicant/add?applicant=${customerDetail?._id}`}
      >
        Add Co-applicant
      </Button>
   </div>
  


    </Box></Fieldset></Grid>
    </CardContent>
</Card>          </Box>}
      </Container>
    </Box>
  </Spin>
  )
}
