import React, { useState } from 'react';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  Button,
  Stack
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../shared/FormInputText';
import { Fieldset } from '../shared/Fieldset';

function FormRow(props) {
  const { control, name, label, rules, inputType, readonly } = props;
  return (
    <React.Fragment>
      <Grid item
        md={6}
        xs={10}
        lg={4}
      >
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
        />
      </Grid>
    </React.Fragment>
  );
}

const BankDetails = ({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setCustomerInfo,
  customerInfo,
  isNextActive
}) => {
  const defaultValues = {
    bankName: customerInfo.bankName || '',
    branchName: customerInfo.branchName || '',
    accountNumber: customerInfo.accountNumber || '',
    ifscCode: customerInfo.ifscCode || '',
    nameOfAccount: customerInfo.nameOfAccount || ''
  };
  const [bankInfo] = useState(defaultValues);

  const { handleSubmit, control } = useForm({
    defaultValues: { bankDetails:{...customerInfo.bankDetails} }
  });

  const onSubmit = (data) => {
    console.log(data);
    setCustomerInfo({
      ...customerInfo, ...data
    })
    handleNext();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <Card>
      
        <CardContent>
        <Box sx={{margin:"24px"}}>
        <Fieldset
            margin="0px"
            // marginTop="25px"
            title={
              <Stack direction="row" alignItems="center" gap={1}>
                Bank Details
              </Stack>
            }
            color="#4984BF"
            titleSize="1.2rem"
            borderWidth={1.5}
            borderRadius={1}
          >
          <Box sx={{display:'flex',alignItems:'flex-start'}}>
          <Grid container spacing={2} margin={"3px"}>
          <Grid container  item spacing={2} mb={1} >
              <FormRow control={control} name="bankDetails.accountName" label="Name Of Account" rules={{ required: CustomerRules.commonValidation("Name Of Account") }} readonly={isNextActive} />
              <FormRow control={control} name="bankDetails.bankName" label="Bank Name" rules={CustomerRules.branchName} readonly={isNextActive} />
              <FormRow control={control} name="bankDetails.branchName" label="Branch Name" rules={CustomerRules.branchName} readonly={isNextActive} />
            </Grid>
            <Grid container  item spacing={2} mb={1} >
              <FormRow control={control} name="bankDetails.accountNumber" label="Account Number" rules={CustomerRules.accountNumber} readonly={isNextActive} />
              <FormRow control={control} name="bankDetails.ifscCode" label="IFSC Code" rules={CustomerRules.ifscCode} readonly={isNextActive} />
            </Grid>
          </Grid>
          </Box>
          </Fieldset>
          </Box>
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
          <Button   variant="contained"
              sx={{ mt: 2, ml: 1 }} onClick={handleBack} >
                Previous
              </Button>
            <Button
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              type="submit"
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
      </Card>
    </form>
  );
};


export default BankDetails;
