import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Modal,
  TextField,
  IconButton,
  Card,
  Divider,
  CardContent,
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
// import { Typography } from 'antd';
import { useSelector } from "react-redux";
import { Fieldset } from "../shared/Fieldset";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormInputDropdown } from "../shared/FormInputDropdown";
import { FormInputText } from "../shared/FormInputText";
import { useForm } from "react-hook-form";
import { ImageUpload } from "../shared/ImageUpload";
import "./style.css";
import { CustomerRules, LoanRules } from "src/utils/validationsConfig";
import CloseIcon from "@mui/icons-material/Close";
import { AntdImageUpload } from "../shared/AntdImageUpload";
import { PastInputDate } from "../shared/FormInputDate";
import CommonApplicantDetails from "./common-applicant-details";
import axios from "axios";
import { services } from "../services/services";
function FormRow(props) {
  const {
    control,
    name,
    label,
    rules,
    inputType,
    readonly,
    multiline = false,
  } = props;
  return (
    <React.Fragment>
      <Grid item md={6} xs={10} lg={4}>
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
          multiline={multiline}
        />
      </Grid>
    </React.Fragment>
  );
}
function FormRowRadio(props) {
  const { control, name, label, options, rules, readonly } = props;
  return (
    <Grid item md={6} xs={10} lg={4}>
      <FormInputDropdown
        name={name}
        control={control}
        label={label}
        options={options}
        readonly={readonly || false}
        rules={rules}
      />
    </Grid>
  );
}

const assetType = [
  {
    label: "Movable",
    value: "movable",
  },
  {
    label: "Immovable",
    value: "immovable",
  },
];
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,

    padding: 0,
    margin: 0,
  },
  addButton: {
    textAlign: "center",
  },
  modalContent: {
    borderRadius: "8px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //     width: 400,
    width: 900,
    backgroundColor: "white",
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
  },
  tableHeader: {
    backgroundColor: "#fafafa", // Custom header background color
    color: "rgba(0, 0, 0, 0.88)",
    fontSize: "13px",
  },
  tableRowCell: {
    fontWeight: "14px",
  },
}));

const AssetDetails = ({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setloanInfo,
  loanInfo,
  isNextActive,
  ApplicantName,
  customerDetail,
}) => {
  const { handleSubmit, control, watch, reset, setValue,getValues } = useForm({
    defaultValues: {},
    values: {},
  });
  const classes = useStyles();
  const globalConfiguration = useSelector(
    (state) => state?.globalConfiguration?.data
  );
  const [rows, setRows] = useState([]);
  const selectedAssetType = watch("assetCategory");
  const selectedTypeOfAsset = watch("assetType");
  const AssetProofImage = watch("assetProofImage");
  const [modalOpen, setModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });
  const [editIndex, setEditIndex] = useState(-1);
  const [isValid, setIsValid] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);
const [storeTempImageUrl, setstoreTempImageUrl] = useState("")
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleYearChange = (newValue) => {
    setSelectedYear(newValue);
  };
  const handleOpenModal = () => {
    setCurrentUser({ name: "", email: "", phone: "", address: "" });
    setEditIndex(-1);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    reset();
    setModalOpen(false);
    setCurrentUser({ name: "", email: "", phone: "", address: "" });
    reset();
  };
  useEffect(() => {
    if (loanInfo?.assetDetails?.length > 0) {
      setRows(loanInfo?.assetDetails);
    }
  }, []);


  const postService = async (service) => {
    let ext = _.split(_.get(service, "name"), ".")[1];
    let customerId = _.get(customerDetail, "customerId");
    let key = getValues(`asset`);
    try {
      let response = await services.postService(`file/upload`, {
        documentType: key,
        documentExtension: ext,
        id: customerId,
      });
      if (response.presignedUrl && response.key) {
        
        setstoreTempImageUrl(response.key)
        await postFiles(service, response.presignedUrl);
      } else {
        console.error("Missing presignedUrl or key", response);
      }
    } catch (err) {
      let errors = err?.data?.results;
      if (errors) {
        // setApiError({
        //   severity: "error",
        //   message: `Validation Error :: ${Object.values(errors).flat(2).join(" ")}`,
        //   error: true
        // });
      }
      throw err;
    }
  };

  const postFiles = async (f, url) => {
    const formData = new FormData();
    formData.append("file", f);
    
    axios({
      method: "put",
      url: url,
      data: f,
    })
      .then(async (res) => {
        
      })
      .catch((err) => {});
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentUser({ ...currentUser, [name]: value });
    setIsValid(
      currentUser.name &&
        currentUser.email &&
        currentUser.phone &&
        currentUser.address
    );
  };

  const handleAddUser = () => {
    if (editIndex > -1) {
      const updatedRows = rows.map((row, index) =>
        index === editIndex ? currentUser : row
      );
      setRows(updatedRows);
    } else {
      setRows([...rows, currentUser]);
    }
    handleCloseModal();
  };

  const handleEdit = (index) => {
    setCurrentUser(rows[index]);
    

    if (rows[index]) {
      
      _.map(rows[index], (v, i) => {
        setValue(i, v);
      });

      // setValue('assetProofImage',rows[index]?.assetProofImage?.fileList?rows[index]?.assetProofImage?.fileList:null)
    }
    setEditIndex(index);
    setModalOpen(true);
  };

  const handleDelete = (index) => {
    setloanInfo({ ...loanInfo, assetDetails:rows.filter((_, i) => i !== index) });
    setRows(rows.filter((_, i) => i !== index));
  };
  const filterLoanProduct = (loanType) => {
    let loanTypes = _.map(
      _.get(globalConfiguration, "loanProducts"),
      (value, key) => ({ value: key, label: value })
    );
    let product = _.filter(
      loanTypes,
      (value, index) => value?.value == loanType
    );
    return _.size(product) > 0
      ? _.get(_.head(product), "label")
      : "Loan Product";
  };
  const onSubmit = (data) => {
    

    if (editIndex != -1) {
      

      let updateRow = [...rows];
      updateRow[editIndex] = data;
      
      setRows([...updateRow]);
      setloanInfo({ ...loanInfo, assetDetails: [...updateRow] });
      reset();
      setModalOpen(false);
    } else {
      

      setRows([...rows, {...data,images:[storeTempImageUrl]}]);
      setloanInfo({ ...loanInfo, assetDetails: [...rows, {...data,images:[storeTempImageUrl]}] });
      reset();
      setModalOpen(false);
    }
  };

  const getAssetTypes = (type) => {
    let list = _.map(
      _.get(globalConfiguration, `assetType[${type}]`, []),
      (value, key) => ({ value: key, label: value })
    );


    return _.size(list) > 0 ? list : [];
  };
  const getPolicyTypes = (type) => {
    let list = _.map(
      _.get(globalConfiguration, `life_insurance`, []),
      (value, key) => ({ value: key, label: value })
    );


    return _.size(list) > 0 ? list : [];
  };

  const removeImage = (ImageIndex) => {
    if (editIndex == -1) {
      const updatedPictures = _.remove(
        [...AssetProofImage],
        (_, index) => index !== ImageIndex
      );
      

      setValue("assetProofImage", updatedPictures);
      // const updatedPictures = _.remove([...AssetProofImage], (_, index) => index !== ImageIndex);
      // let filterRows = [...rows]
      // filterRows[editIndex].assetProofImage =_.remove([...filterRows[editIndex]?.assetProofImage], (_, index) => index !== ImageIndex)
    } else {
    }
    // const updatedPictures = _.remove([...pictures], (_, index) => index !== indexToRemove);
    // setPictures(updatedPictures);
  };
  function formatIndianNumber(value) {
    if (!_.isNumber(value)) return value;
  
    const number = _.toString(value);
    const lastThree = number.slice(-3);
    const rest = number.slice(0, -3);
  
    return rest.length > 0
      ? rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree
      : lastThree;
  }
  const arrangeObject = (asset) => {
    // const commonFilterFields = (fields)=>{
    //   _.filter
    // }
    let orderedFields;


    // Determine field order based on asset type or assetType
    if (asset?.assetType === "gold") {
      orderedFields = ["assetCategory","assetType", "assetMetadata.weightInGrams", "assetValue"];
    } else if (asset?.assetType === "bank_fixed_deposit") {
      orderedFields = ["assetCategory","assetType", "assetMetadata.bankName", "assetMetadata.depositAmount"];
    } else if (
      [
        "life_insurance"
      ].includes(asset?.assetType)
    ) {
      orderedFields = ["assetCategory","assetType", "assetMetadata.policyNumber"];
    }else if (
      [
        "four_wheeler",
        "machinery",
        "two_wheeler",
        "commercial_vehicle",
        "car"
      ].includes(asset?.assetType)
    ) {
      orderedFields = ["assetCategory","assetType", "assetMetadata.vehicleNumber", "assetValue"];
    }
     else if (asset?.assetCategory === "immovable") {
      orderedFields = ["assetCategory","assetType", "assetMetadata.sizeInSqFeet", "assetValue"];
    } else {
      // Default order if no conditions match
      orderedFields = Object.keys(asset);
    }
    const result = _.map(orderedFields, (key) => {
      const value = _.get(asset, key); // Use _.get to handle nested properties
      return { [key.split(".").pop()]: value }; // Flatten the key if nested
    }).reduce((acc, item) => Object.assign(acc, item), {});

    return result;
  };
 
  const renderCommonFields1 = (v, i, item) => {
    console.log('skandjasjdas',v, i, item);
    
    return (i!="assetMetadata"&&
      v && (
        <Box
          key={i}
          sx={{
            // display: 'flex',
            alignItems: "center",
            flexDirection: "column",
            //   border:`1px solid #eaecf0`,
            borderRadius: "8px",
            padding: "3px",
            position: "relative",
            //   width: '20%',
            marginRight: "12px",
            width: "18%",
            //   marginBottom:'14px'
          }}
        >
          <Typography
            sx={{
              color: "#172b4d",
              fontSize: "15px",
              fontWeight: "500",
            }}
          >
            {i == "assetCategory" || i == "assetType"
              ? _.startCase(v)
              : i == "weightInGrams"
              ? `${v} grams`
              : i == "sqFt"
              ? `${v} Sq.Ft`
              : v}
          </Typography>
        </Box>
      )
    );
  };

  const renderCommonCard = () => {
    return _.map(rows, (item, index) => {
      return (
        <Card
          key={index}
          sx={{
            border: "1px solid #eaecf0",
            borderRadius: "12px",
            boxShadow: "0 4px 8px #0000001a",
            backgroundColor: "##fff",
            marginTop: "12px",
            paddingBottom: "0px",
            marginLeft: "16px",
            marginRight: "16px",
          }}
        >
          <CardContent
            sx={{
              paddingBottom: "0px !important",
              marginLeft: "12px",
              marginBottom: "12px",
            }}
          >
            <div style={{ display: "flex", width: "100%" }}>
              <Box sx={{ width: "90%", display: "flex" }}>
                <Grid
                  container
                  item
                  spacing={2}
                  mb={"0px"}
                  mt={0}
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  {_.map(arrangeObject(item), (v, i) => {
                    return  (
                      i != "assetValue" &&
                      i != "depositAmount" &&
                      renderCommonFields1(v, i, item)
                    );
                  })}
                </Grid>
                <Box>
                  <Grid
                    container
                    item
                    spacing={2}
                    mb={"0px"}
                    mt={0}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box
                      sx={{
                        borderRadius: "8px",
                        padding: "3px",
                        position: "relative",
                        marginRight: "12px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgb(52, 64, 84)",
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        ₹
                        {item?.assetType=="life_insurance"? 
                        formatIndianNumber(parseFloat(item?.assetMetadata?.premium)): item?.assetMetadata?.depositAmount
                          ? formatIndianNumber(parseFloat(item?.assetMetadata?.depositAmount))
                          : formatIndianNumber(parseFloat(item?.assetValue))}
                      </Typography>
                    </Box>
                  </Grid>
                </Box>
              </Box>

              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                sx={{ marginTop: "0px", paddingTop: "0px", width: "13%" }}
              >
                <IconButton
                  onClick={() => handleEdit(index)}
                  sx={{
                    border: "1px solid #4984BF",
                    borderRadius: "8px",
                    padding: "8px",
                    marginRight: "8px",
                    width: "30px",
                    height: "30px",
                  }}
                >
                  <Edit sx={{ fontSize: "13px" }} />
                </IconButton>
                <IconButton
                  onClick={() => handleDelete(index)}
                  sx={{
                    border: "1px solid #4984BF",
                    borderRadius: "8px",
                    width: "30px",
                    height: "30px",
                  }}
                >
                  <Delete sx={{ fontSize: "13px" }} />
                </IconButton>
              </Box>
            </div>
          </CardContent>
        </Card>
      );
    });
  };


  const enableFieldsDependOnAsset = () => {
    if(selectedAssetType=="movable"){
      if (
        [
          "four_wheeler",
          "machinery",
          "two_wheeler",
          "commercial_vehicle",
          "car",
          "others"
          // "other"
        ].includes(selectedTypeOfAsset)
      ) {
        return true;
      } else {
        return false;
      }
    }else{
      if (
        [
          "four_wheeler",
          "machinery",
          "two_wheeler",
          "commercial_vehicle",
          "car",
          // "other"
        ].includes(selectedTypeOfAsset)
      ) {
        return true;
      } else {
        return false;
      }
    }
    
  };

  const filterCoApplicant = (id) => {
    if (_.size(_.get(customerDetail, "coApplicants")) > 0) {
      let coApplicants = _.filter(
        _.get(customerDetail, "coApplicants"),
        (value, index) => value?._id == id
      );
      return {
        coApplicantName:
          _.size(coApplicants) > 0
            ? _.startCase(
                coApplicants[0].firstName + " " + coApplicants[0].lastName
              )
            : "-",
        coApplicantId: coApplicants[0]?.customerId,
        coApplicantNumber: coApplicants[0]?.mobileNumber,
      };
    } else {
      return null;
    }
  };
  return (
    <Card
      sx={{
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        // backgroundColor: "#f9f9fc",
        padding: "16px",
      }}
    >
      {/* <Divider /> */}
      <CardContent style={{paddingTop:"23px"}}>
        <CommonApplicantDetails
          applicantName={ApplicantName}
          applicantNumber={customerDetail?.mobileNumber}
          applicantId={customerDetail?.customerId}
          LoanName={filterLoanProduct(loanInfo?.loanProduct)}
          loanCode={loanInfo?.loanProduct}
          coApplicant={filterCoApplicant(loanInfo?.coApplicantIdMongo)}
        />
        <Box sx={{ padding: "10px" }}>
          <Fieldset
            margin="0px"
            marginTop="25px"
            title={
              <Stack direction="row" alignItems="center" gap={1}>
                Assets Details
              </Stack>
            }
            color="#4984BF"
            titleSize="1.2rem"
            borderWidth={1.5}
            borderRadius={1}
          >
            {renderCommonCard()}
            <Box display={"flex"} justifyContent={"center"} marginTop={"12px"}>
              <Button
                startIcon={
                  <AddCircleOutlineIcon style={{ fill: "#FFF" }} color="#FFF" />
                }
                variant="contained"
                style={{ textTransform: "none" }}
                color="primary"
                onClick={handleOpenModal}
              >
                Add Asset
              </Button>
            </Box>
          </Fieldset>
        </Box>

        <Modal open={modalOpen} onClose={handleCloseModal}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.modalContent}>
              <h2>{editIndex > -1 ? "Edit Asset" : "Add Asset"}</h2>
              <Grid container item spacing={3} mb={3} mt={0}>
                <FormRowRadio
                  name="assetCategory"
                  control={control}
                  label="Asset"
                  options={assetType}
                  rules={{ required: CustomerRules.commonValidation("Asset") }}
                />
                {selectedAssetType && (
                  <FormRowRadio
                    name="assetType"
                    control={control}
                    label="Type of Asset"
                    options={getAssetTypes(selectedAssetType)}
                    rules={{
                      required: CustomerRules.commonValidation("Type of Asset"),
                    }}
                  />
                )}
                {selectedTypeOfAsset != "bank_fixed_deposit" &&selectedTypeOfAsset != "life_insurance"
                  ? 
                  selectedAssetType &&
                    selectedTypeOfAsset && (
                      <>
                        
                        {selectedTypeOfAsset != "gold" && (
                          <FormRow
                          inputType="number"
                            name="assetMetadata.purchaseYear"
                            control={control}
                            label="Purchase Year"
                            rules={{
                              required:
                                CustomerRules.commonValidation("Purchase Year"),
                            }}
                          />
                        )}
                        {enableFieldsDependOnAsset(selectedAssetType) ? (<>
                          <FormRow
                            control={control}
                            name="assetMetadata.manufacturer"
                            label={"Manufacturer"}
                            rules={{
                              required:
                                CustomerRules.commonValidation(
                                  "Manufacturer"
                                ),
                            }}
                          />
                          <FormRow
                            control={control}
                            name="assetMetadata.model"
                            label={"Model"}
                            rules={{
                              required:
                                CustomerRules.commonValidation(
                                  "Model"
                                ),
                            }}
                          />
                          </>
                        ) : (
                          selectedAssetType == "immovable" && (
                            <FormRow
                              control={control}
                              name="assetMetadata.documentNumber"
                              label={"Document No"}
                              rules={{
                                required:
                                  CustomerRules.commonValidation("Document No"),
                              }}
                            />
                          )
                        )}
                        {selectedAssetType == "immovable" ? (
                          <FormRow
                            control={control}
                            name="assetMetadata.sizeInSqFeet"
                            label={"Sq. Ft"}
                            rules={{
                              required:
                                CustomerRules.commonValidation("Sq. Ft/Gms"),
                            }}
                          />
                        ) : (
                          selectedTypeOfAsset == "gold" && (
                            <FormRow
                             inputType="number"
                              control={control}
                              name="assetMetadata.weightInGrams"
                              label={"weight In Grams"}
                              rules={{
                                required:
                                  CustomerRules.commonValidation("Grms"),
                              }}
                            />
                          )
                        )}

                        {enableFieldsDependOnAsset() == true ? (
                          <FormRow
                            control={control}
                            name="assetMetadata.vehicleNumber"
                            label={"Vehicle No"}
                            rules={{
                              required:
                                CustomerRules.commonValidation("Vehicle No"),
                            }}
                          />
                        ) : (
                          selectedAssetType == "immovable" && (
                            <FormRow
                              control={control}
                              name="assetMetadata.surveyNumber"
                              label={"Survey No"}
                              rules={{
                                required:
                                  CustomerRules.commonValidation("Survey No"),
                              }}
                            />
                          )
                        )}
                        {selectedTypeOfAsset != "gold" && (
                          <FormRow
                            control={control}
                            name="assetMetadata.location"
                            label="Location"
                            rules={{
                              required:
                                CustomerRules.commonValidation("Location"),
                            }}
                          />
                        )}
{selectedTypeOfAsset != "gold" &&<FormRow
                          control={control}
                          name="assetMetadata.ownerName"
                          label="Owner Name"
                          rules={{
                            required: CustomerRules.commonValidation("Owner Name"),
                          }}
                        />}

                        <FormRow
                        inputType="number"
                          control={control}
                          name="assetValue"
                          label="Value"
                          rules={{
                            required: CustomerRules.commonValidation("Value"),
                          }}
                        />
                      </>
                    )
                  : selectedTypeOfAsset == "bank_fixed_deposit"  ?
                    selectedTypeOfAsset && (
                      <>
                        <FormRow
                          control={control}
                          name="assetMetadata.bankName"
                          label="Name of Bank/NBFC"
                          rules={{
                            required:
                              CustomerRules.commonValidation(
                                "Name of Bank/NBFC"
                              ),
                          }}
                        />
                        <FormRow
                          control={control}
                          name="assetMetadata.bankBranch"
                          label="Branch"
                          rules={{
                            required: CustomerRules.commonValidation("Branch"),
                          }}
                        />
                        <FormRow
                          control={control}
                          name="assetMetadata.bankBranchCode"
                          label="Branch Code"
                          rules={{
                            required:
                              CustomerRules.commonValidation("Branch Code"),
                          }}
                        />
                        <FormRow
                          control={control}
                          name="assetMetadata.nameOfDepositor"
                          label="Name Of Depositor"
                          rules={{
                            required:
                              CustomerRules.commonValidation(
                                "Name Of Depositor"
                              ),
                          }}
                        />
                        <FormRow
                          control={control}
                          name="assetMetadata.accountNumber"
                          label="Account Number"
                          rules={{
                            required:
                              CustomerRules.commonValidation("Account Number"),
                          }}
                        />
                        <FormRow
                          control={control}
                          name="assetMetadata.depositCertificateNumber"
                          label="Certificate Number"
                          rules={{
                            required:
                              CustomerRules.commonValidation(
                                "Certificate Number"
                              ),
                          }}
                        />
                        <FormRow
                          control={control}
                          inputType="number"
                          name="assetMetadata.depositAmount"
                          label="Deposit Amount"
                          rules={{
                            required:
                              CustomerRules.commonValidation("Deposit Amount"),
                          }}
                        />
                        <FormRow
                          control={control}
                          inputType="number"
                          name="assetMetadata.depositPeriod"
                          label="Deposit Period"
                          rules={{
                            required:
                              CustomerRules.commonValidation("Deposit Period"),
                          }}
                        />
                        <Grid item md={6} xs={10} lg={4}>
                          <PastInputDate
                            validation={false}
                            control={control}
                            name="assetMetadata.depositDate"
                            label="Deposit Date"
                            rules={{
                              required:
                                CustomerRules.commonValidation("Deposit Date"),
                            }}
                            required={true}
                          />
                        </Grid>
                        <Grid item md={6} xs={10} lg={4}>
                          <PastInputDate
                            validation={false}
                            control={control}
                            name="assetMetadata.maturityDate"
                            label="Maturity Date"
                            rules={{
                              required:
                                CustomerRules.commonValidation("Maturity Date"),
                            }}
                            required={true}
                          />
                        </Grid>
                        <FormRow
                          control={control}
                          name="assetMetadata.nomineeName"
                          label="Nominee Name"
                          rules={{
                            required:
                              CustomerRules.commonValidation("Nominee Name"),
                          }}
                        />
                      </>
                    ):
                    selectedTypeOfAsset && (
                      <>
                      <FormRowRadio
                    name="assetMetadata.insuranceType"
                    control={control}
                    label="Insurance Type"
                    options={getPolicyTypes(selectedAssetType)}
                    rules={{
                      required: CustomerRules.commonValidation("Insurance Type"),
                    }}
                  />
                        <FormRow
                          control={control}
                          name="assetMetadata.providerName"
                          label="Name of Company"
                          rules={{
                            required:
                              CustomerRules.commonValidation(
                                "Name of Name of Company"
                              ),
                          }}
                        />
                        <FormRow
                          control={control}
                          name="assetMetadata.branch"
                          label="Branch"
                          rules={{
                            required: CustomerRules.commonValidation("Branch"),
                          }}
                        />
                        <FormRow
                          control={control}
                          name="assetMetadata.branchCode"
                          label="Branch Code"
                          rules={{
                            required:
                              CustomerRules.commonValidation("Branch Code"),
                          }}
                        />
                        <FormRow
                          control={control}
                          name="assetMetadata.insuranceName"
                          label="Insurance Name"
                          rules={{
                            required:
                              CustomerRules.commonValidation(
                                "Insurance Name"
                              ),
                          }}
                        />
                        <FormRow
                          control={control}
                          name="assetMetadata.policyNumber"
                          label="Policy Number"
                          rules={{
                            required:
                              CustomerRules.commonValidation("Policy Number"),
                          }}
                        />
                        <FormRow
                          control={control}
                          name="assetMetadata.certificateNumber"
                          label="Certificate Number"
                          rules={{
                            required:
                              CustomerRules.commonValidation(
                                "Certificate Number"
                              ),
                          }}
                        />
                        <FormRow
                          control={control}
                          inputType="number"
                          name="assetMetadata.sumAssured"
                          label="Sum Assured"
                          rules={{
                            required:
                              CustomerRules.commonValidation("Sum Assured"),
                          }}
                        />
                        <FormRow
                          control={control}
                          inputType="number"
                          name="assetMetadata.numberOfYears"
                          label="No.of.Years"
                          rules={{
                            required:
                              CustomerRules.commonValidation("No.of.Years"),
                          }}
                        />
                        <FormRow
                          control={control}
                          inputType="number"
                          name="assetMetadata.premium"
                          label="Premium"
                          rules={{
                            required:
                              CustomerRules.commonValidation("Premium"),
                          }}
                        />
                        <Grid item md={6} xs={10} lg={4}>
                          <PastInputDate
                            validation={false}
                            control={control}
                            name="assetMetadata.startDate"
                            label="Start Date"
                            rules={{
                              required:
                                CustomerRules.commonValidation("Start Date"),
                            }}
                            required={true}
                          />
                        </Grid>
                        
                        <FormRow
                          control={control}
                          name="assetMetadata.nomineeName"
                          label="Nominee Name"
                          rules={{
                            required:
                              CustomerRules.commonValidation("Nominee Name"),
                          }}
                        />
                      </>
                    )}
              </Grid>
              <Box
                container
                item
                spacing={2}
                mb={3}
                mt={3}
                sx={{ marginTop: "45px" }}
              >
                <AntdImageUpload
                  uploadText="Upload Asset images"
                  withPreview={false}
                  multiple={true}
                  name="assetProofImage"
                  label="Upload asset document"
                  control={control}
                  disabled={!getValues('assetType') || !getValues('asset')}
                  onUploadDocument={(item)=>{
                    
                    postService(item?.file)

                  }}
                />
              </Box>
              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{ border: "1px solid rgb(204, 204, 204)" }}
                  variant="contained"
                  color="primary"
                  onClick={handleCloseModal}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  // onClick={handleAddUser}
                  // disabled={!isValid}
                >
                  {editIndex > -1 ? "Save" : "Add"}
                </Button>
              </div>
            </div>
          </form>
        </Modal>
      </CardContent>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "space-between", px: 2, pb: 2 }}>
        
          {activeStep !== 0 && (
            <Button onClick={() => handleBack()}  variant="contained"  sx={{ mt: 2, ml: 1 }}>
              Previous
            </Button>
          )}
          <Button
            onClick={() => {
              if (rows.length > 0) {
               
                
                setloanInfo({ ...loanInfo, assetDetails: rows });
                handleNext();
              }
            }}
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            // type="submit"
          >
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
       
      </Box>
    </Card>
  );
};

export default AssetDetails;
