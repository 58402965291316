
import React, { useState } from 'react'
import { Stack, Button, Modal, TextField, } from '@mui/material';
import { Box, TableContainer, TableRow, Table, TableBody } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import _ from "lodash";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
// import { ImageUpload } from '../../components/shared/ImageUpload';
import ImageUploader from "react-images-upload";
import PerfectScrollbar from 'react-perfect-scrollbar'
import axios from "axios"
import 'react-perfect-scrollbar/dist/css/styles.css';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
// const TabelHeaderCell = styled(TableCell)(({ theme }) => ({
//   backgroundColor: '#EDE7F6',
//   color: '#5D35B1'
// }))

const MuiTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none"
  }
}));

const BoldTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontWeight: '500'
  }
}));
const ApprovedScreen = (props) => {
  
  let loan_details_amount = parseFloat(_.get(props, 'loan_details.loan_amount'))
  let customerId = parseFloat(_.get(props, 'loan_details.customerId'))

  // const [serviceCharge, setServiceCharge] = useState(((1 * loan_details_amount) / 100))
  const [comments, setComments] = useState('')
  // const [gsdCharge, setGsdCharge] = useState(((loan_details_amount * 20000) / 100))
  const [modalTitle, setModalTitle] = useState('disbursed');
  const [loanDisburseDetails, setLoanDisburseDetails] = useState({
    loan_amount: loan_details_amount,
    service_charge: (1 * loan_details_amount) / 100,
    sgst: (9 * (1 * loan_details_amount) / 100) / 100,
    cgst: (9 * (1 * loan_details_amount) / 100) / 100,
    disbursed_amount: loan_details_amount - (((1 * loan_details_amount) / 100) + ((9 * (1 * loan_details_amount) / 100) / 100) + ((9 * (1 * loan_details_amount) / 100) / 100)),
    payment_mode: 'cash',
    e_transfer_type: 'upi',
    accountNumber: '',
    bankName: '',
    disbursed_date: moment().utc().toString(),
    due_start_date: moment().add(1, 'month').utc().toString(),
    screenshot: null

  });
  // let date = data?.dob?.toISOString().split("T")[0];
  const editServiceCharges = (value) => {
    let a = ((9 * value.target.value) / 100) * 2
    let b = value.target.value
    
    setLoanDisburseDetails({
      ...loanDisburseDetails, service_charge: value.target.value,
      disbursed_amount: loan_details_amount - (parseFloat(a) + parseFloat(b)),
      sgst: (9 * value.target.value) / 100,
      cgst: (9 * value.target.value) / 100,

    })

  }

  const onSubmitForm = () => {
    
    if (_.get(loanDisburseDetails, 'screenshot[0]')) {
      postService(_.get(loanDisburseDetails, 'screenshot[0]'))
    } else {
      let disbursed_date = moment(loanDisburseDetails.disbursed_date).format('YYYY/MM/DD')
      let due_start_date = moment(loanDisburseDetails.due_start_date).format('YYYY/MM/DD')
      props.onChangeLoanStatus({
        comments: ['disbursed done'], loan_status: 'disbursed',
        ...loanDisburseDetails,
        sgst: loanDisburseDetails.sgst,
        cgst: loanDisburseDetails.sgst,
        disbursed_date,
        due_start_date
      })
        ; props.handleClose()
    }
  }

  const postService = async (service) => {
    axios({
      method: 'post',
      url: `https://1sjtwuxmcl.execute-api.ap-south-1.amazonaws.com/qa/upload-file`,
      data: { customerId: customerId, fileName: _.get(service, 'name') },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then((res) => {
      let k = `${customerId}/${_.get(service, 'name')}`
      postFiles(service, _.get(res, 'data.presignedUrl'), k)

    }).catch((err) => {

    })

  }
  const postFiles = async (f, url, k) => {

    const formData = new FormData();
    formData.append('file', f);
    axios({
      method: 'put',
      url: url,
      data: f,
      // params:{url:url}
    }).then(async (res) => {

      setLoanDisburseDetails({
        ...loanDisburseDetails,
        screenshot: k
      })
      let disbursed_date = moment(loanDisburseDetails.disbursed_date).format('YYYY/MM/DD')
      let due_start_date = moment(loanDisburseDetails.due_start_date).format('YYYY/MM/DD')
      props.onChangeLoanStatus({
        comments: ['disbursed done'], loan_status: 'disbursed',
        ...loanDisburseDetails,
        sgst: loanDisburseDetails.sgst,
        cgst: loanDisburseDetails.sgst,
        screenshot: k,
        disbursed_date,
        due_start_date
      })
        ; props.handleClose()


    }).catch((err) => {
      

    })



  }




  return <>
    <Stack style={{ marginTop: 23 }}
      // direction="row"
      justifyContent="space-between"
      alignItems="center" direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 1, sm: 2, md: 4 }} >
      <Button
        style={{ cursor: 'pointer' }}
        variant="contained"
        color="success"
        // sx={{ mt: 2, ml: 1 }}
        onClick={() => { setModalTitle('disbursed'); props.handleOpen() }}

      >
        Disburse
      </Button>
      <Button
        color="error"
        variant="contained"
        style={{ cursor: 'pointer' }}
        // sx={{ mt: 2, ml: 1 }}
        onClick={() => { setModalTitle('rejected'); props.handleOpen() }}
      >
        Reject
      </Button>

    </Stack>
    {modalTitle === "disbursed" ? <Modal

      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >

      <Box sx={{ ...style, width: 600, borderRadius: '25px', borderColor: '#038dd7', borderWidth: '5px' }}>
        <h2 id="parent-modal-title">Approve</h2>
        <PerfectScrollbar style={{ height: 700 }}>
          <TableContainer style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Table sx={{ minWidth: 600 }} size="small" aria-label="simple table">
              <TableBody>
                <TableRow hover>
                  <BoldTableCell>Loan Amount</BoldTableCell>
                  <MuiTableCell>:</MuiTableCell>
                  <MuiTableCell>{loanDisburseDetails.loan_amount}</MuiTableCell>
                </TableRow>
                <TableRow hover>
                  <BoldTableCell>Service Charge</BoldTableCell>
                  <MuiTableCell>:</MuiTableCell>
                  <MuiTableCell><TextField type="number" value={loanDisburseDetails.service_charge} onChange={(value) => editServiceCharges(value)} /> {' '} {'(1%) Charges'}</MuiTableCell>
                </TableRow>
                <TableRow hover>
                  <BoldTableCell>SGST</BoldTableCell>
                  <MuiTableCell>:</MuiTableCell>
                  <MuiTableCell><TextField InputProps={{
                    readOnly: true,
                  }} type="number"
                    onChange={(value) => setLoanDisburseDetails({
                      ...loanDisburseDetails,
                      sgst: (value.target.value * 100) / loan_details_amount,
                      disbursed_amount: loan_details_amount - ((value.target.value * 100) / loan_details_amount + loanDisburseDetails.service_charge)
                    })} value={loanDisburseDetails.sgst} /> {' '} {'(9%) Charges'}</MuiTableCell>
                </TableRow>
                <TableRow hover>
                  <BoldTableCell>CGST</BoldTableCell>
                  <MuiTableCell>:</MuiTableCell>
                  <MuiTableCell><TextField InputProps={{
                    readOnly: true,
                  }} type="number"
                    onChange={(value) => setLoanDisburseDetails({
                      ...loanDisburseDetails,
                      sgst: (value.target.value * 100) / loan_details_amount,
                      disbursed_amount: loan_details_amount - ((value.target.value * 100) / loan_details_amount + loanDisburseDetails.service_charge)
                    })} value={loanDisburseDetails.sgst} /> {' '} {'(9%) Charges'}</MuiTableCell>
                </TableRow>
                <TableRow hover>
                  <BoldTableCell>Disburse Amount</BoldTableCell>
                  <MuiTableCell>:</MuiTableCell>
                  <MuiTableCell>{loanDisburseDetails.disbursed_amount}</MuiTableCell>
                </TableRow>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TableRow hover>
                    <BoldTableCell>Disbursed Date</BoldTableCell>
                    <MuiTableCell>:</MuiTableCell>
                    <MuiTableCell>
                      <DesktopDatePicker
                        label="Disbursed Date"
                        inputFormat="MM/dd/yyyy"
                        value={_.get(loanDisburseDetails, 'disbursed_date')}
                        onChange={(value) => setLoanDisburseDetails({ ...loanDisburseDetails, disbursed_date: value })}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </MuiTableCell>
                  </TableRow>
                  <TableRow hover>
                    <BoldTableCell>Due Start Date</BoldTableCell>
                    <MuiTableCell>:</MuiTableCell>
                    <MuiTableCell>
                      <DesktopDatePicker

                        label="Due Start Date"
                        inputFormat="MM/dd/yyyy"
                        value={_.get(loanDisburseDetails, 'due_start_date')}
                        onChange={(value) => setLoanDisburseDetails({ ...loanDisburseDetails, due_start_date: value })}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </MuiTableCell>
                  </TableRow></LocalizationProvider>
                <TableRow hover>
                  <BoldTableCell>Payment Type</BoldTableCell>
                  <MuiTableCell>:</MuiTableCell>
                  <MuiTableCell> <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={_.get(loanDisburseDetails, 'payment_mode')}
                    label="Payment Type"
                    onChange={(e) => setLoanDisburseDetails({ ...loanDisburseDetails, payment_mode: e.target.value })}
                  >
                    <MenuItem value={'etransfer'}>etransfer</MenuItem>
                    <MenuItem value={'bank_deposit'}>Bank Deposit</MenuItem>
                    <MenuItem value={'cash'}>Cash</MenuItem>
                  </Select></MuiTableCell>
                </TableRow>
                {_.get(loanDisburseDetails, 'payment_mode') !== 'cash' && <TableRow hover>
                  <BoldTableCell>E-Transfer Type</BoldTableCell>
                  <MuiTableCell>:</MuiTableCell>
                  <MuiTableCell> <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={_.get(loanDisburseDetails, 'e_transfer_type')}
                    label="E-Transfer Type"
                    onChange={(e) => setLoanDisburseDetails({ ...loanDisburseDetails, e_transfer_type: e.target.value })}
                  >
                    <MenuItem value={'upi'}>upi</MenuItem>
                    <MenuItem value={'neft_rtgs_imps'}>NEFT RTGS IMPS</MenuItem>
                  </Select></MuiTableCell>
                </TableRow>}
                <TableRow hover>
                  <BoldTableCell>Bank Name</BoldTableCell>
                  <MuiTableCell>:</MuiTableCell>
                  <MuiTableCell><TextField onChange={(e) => setLoanDisburseDetails({
                    ...loanDisburseDetails,
                    bankName: e.target.value
                  })} /></MuiTableCell>
                </TableRow>
                <TableRow hover>
                  <BoldTableCell>Account Number</BoldTableCell>
                  <MuiTableCell>:</MuiTableCell>
                  <MuiTableCell><TextField onChange={(e) => setLoanDisburseDetails({
                    ...loanDisburseDetails,
                    accountNumber: e.target.value
                  })} /></MuiTableCell>
                </TableRow>




              </TableBody>

            </Table>
            <ImageUploader
              multiple={false}
              withIcon={false}
              withPreview={true}
              name={'attachment'}
              label={'attachment'}
              onChange={(event) => {
                setLoanDisburseDetails({ ...loanDisburseDetails, screenshot: event })
              }}
              singleImage={true}
              imgExtension={[".jpg", ".gif", ".png", '.jpeg']}
              maxFileSize={5242880}
              fileSizeError=" file size is too big"

            />
          </TableContainer>
          <Button
            style={{ cursor: 'pointer' }}
            variant="contained"
            color="success"
            // sx={{ mt: 2, ml: 1 }}
            onClick={() => {
              onSubmitForm()
            }}

          >
            Submit
          </Button></PerfectScrollbar>
      </Box>
    </Modal> : <Modal

      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <h2 id="parent-modal-title">{'Rejected'}</h2>

        <Stack style={{ marginTop: 23 }} direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <TextField
            fullWidth="78"
            id="outlined-multiline-static"

            label="Comments"
            multiline

            rows={4}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
          <Stack direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2} >
            <Button
              style={{ cursor: 'pointer' }}
              variant="contained"
              color="success"
              // sx={{ mt: 2, ml: 1 }}
              onClick={() => { comments && props.onChangeLoanStatus({ comments: [comments], loan_status: 'rejected' }); props.handleClose() }}

            >
              Reject
            </Button>
            <Button
              style={{ cursor: 'pointer' }}
              variant="contained"
              // color="success"
              // sx={{ mt: 2, ml: 1 }}
              onClick={props.handleClose}

            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>}
  </>
}

export default ApprovedScreen;