import React from "react";

import { Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import moment from "moment";
export const PastInputDate = ({ name, control, label, rules, required = false,validation=true }) => {
  const minAge = 18;
  const maxAge = 55;
  const currentDate = new Date();
  const minDate = new Date(currentDate.getFullYear() - maxAge, currentDate.getMonth(), currentDate.getDate());
  const maxDate = new Date(currentDate.getFullYear() - minAge, currentDate.getMonth(), currentDate.getDate());

  const shouldDisableDate = (date) => {
    return date < minDate || date > maxDate;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <DatePicker
            // shouldDisableDate={validation?shouldDisableDate:false}
            label={label}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error?.message,
                size: 'small',
                placeholder:"",
                style:{lineHeight:'24px',fontSize:'1.3rem',},
                InputLabelProps: {
                  shrink: true, 
                  style: { fontSize:'1.3rem',lineHeight:'24px'},
                },
                sx:{
                  '& .MuiOutlinedInput-notchedOutline': {
                    fontSize:'1.3rem',
                  },
                  width:"100%" 
                }
              },
            }}
            format="dd/MM/yyyy"
            inputFormat="dd/MM/yyyy"
            onChange={onChange}
            value={value || null}
            sx={{
              width:"100%" 
            }}
            renderInput={(params) => (
              <TextField
              
                required={required}
                {...params}
                // size="small"
                fullWidth
                style={{ height: 13}}
                helperText={error ? error.message : null}
                error={!!error}
                InputLabelProps={{
              shrink: true, 
              style: { fontSize:'1.3rem',lineHeight:'24px' }, 
            }}
            sx={{
              width:"100%" ,
              '& .MuiOutlinedInput-notchedOutline': {
                fontSize:'1.3rem',
              }
            }}
            placeholder=""
              />
            )}
          />
        )}
        rules={{
          ...rules
        }}
      />
    </LocalizationProvider>
  );
};
export const FutureDateInput = ({ name, control, label, rules, required = false }) => {
  const currentDate = new Date();
  // currentDate.setHours(0, 0, 0, 0);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <DatePicker
            shouldDisableDate={(date) => date < currentDate}
            label={label}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error?.message,
                size: 'small'
              },
            }}
            format="dd/MM/yyyy"
            inputFormat="dd/MM/yyyy"
            onChange={onChange}
            value={value || null}
            sx={{
              width:"100%" 
            }}
            renderInput={(params) => (
              <TextField
                required={required}
                {...params}
                // size="small"
                fullWidth
                helperText={error ? error.message : null}
                error={!!error}
                style={{ height: 13}}
              />
            )}
          />
        )}
        rules={{
          ...rules
        }}
      />
    </LocalizationProvider>
  );
}