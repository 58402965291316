import CustomerList from "src/pages/CustomerList"



const ApplicantListScreen =() =>{

       return <CustomerList customer_type="applicant" />
}


export default ApplicantListScreen