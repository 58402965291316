import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Review from 'src/components/customer/Review';
import CustomerAccountInfo from 'src/components/customer/CustomerAccountInfo';
import { Helmet } from 'react-helmet';
import CustomerPersonalInfo from 'src/components/customer/CustomerPersonalInfo';
import AddressDetails from 'src/components/customer/AddressDetails';
import Proofs from 'src/components/customer/Proofs';
import BankDetails from 'src/components/customer/BankDetails';
import GuarantorDetails from 'src/components/customer/GuarantorDetails';
import FamilyDetailsInfo from 'src/components/customer/FamilyDetailsInfo';
import { services } from 'src/components/services/services';
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import BreadcrumbsComponent from 'src/components/Breadcrumbs';

import { isMobile } from 'react-device-detect';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

let defaultDetails ={
  "firstName": "sadsadssad",
  "lastName": "dasdsasad",
  "mobileNumber": "3434343434",
  "aadharId": "435345345433",
  "dob": "2025-01-02T18:30:00.000Z",
  "gender": "male",
  "photo": "dev/customers/profile_20250102084226.jpg",
  "spouseName": "ewrew",
  "fathersName": "ewrew",
  "mothersName": "ewrwe",
  "education": "hsc",
  "maritalStatus": "married",
  "religion": "muslim",
  "caste": "sc",
  "physicallyChallenged": "no",
  "occupation": "self_employed",
  "addressDetails": {
         "present": {
                "residenceType": "rent",
                "houseNumber": "ewr",
                "addressLine1": "ewrew",
                "addressLine2": "ewrwe",
                "cityVillageTown": "ewrew",
                "district": "werwe",
                "landmark": "ewrew",
                "state": "TN",
                "pinCode": "werewr",
                "noOfYears": 11,
                "noOfMonths": 4
         },
         "permanent": {
                "houseNumber": "ewr",
                "addressLine1": "ewrew",
                "addressLine2": "ewrwe",
                "cityVillageTown": "ewrew",
                "district": "werwe",
                "landmark": "ewrew",
                "state": "TN",
                "pinCode": "werewr",
                "noOfYears": 11,
                "noOfMonths": 4,
                "sameAsPermanent": true
         },
         "permanentSameAsPresent": true
  },
  "familyDetails": [
         {
                "name": "ewrw",
                "age": "44",
                "gender": "female",
                "address": "drwer",
                "relationship": "wife",
                "occupation": "central_government",
                "income": "453534",
                "mobileNumber": "4354354354"
         }
  ],
  "references": [
         {
                "name": "dsfdsf",
                "age": "45",
                "gender": "male",
                "address": "sdfdsfs",
                "relationship": "father",
                "occupation": "private_organization",
                "aadharId": "243234324233",
                "mobileNumber": "2343423423"
         }
  ],
  "bankDetails": {
         "accountName": "test",
         "bankName": "tetet",
         "branchName": "dfds",
         "accountNumber": "dsfd434",
         "ifscCode": "dfdsfds"
  },
  "documents": {
         "addressDocuments": [
                {
                       "type": "aadhaar",
                       "uniqueNo": "43534",
                       "url": "dev/temp/aadhaar_20250102084430.svg"
                }
         ],
         "idDocuments": [
                {
                       "type": "driving_license",
                       "uniqueNo": "3534543",
                       "url": "dev/temp/driving_license_20250102084436.jpg"
                }
         ]
  }
}


export default function AddEditCustomer(props) {
  let { id } = useParams();

  const [searchParams] = useSearchParams();
  const [activeStep, setActiveStep] = React.useState(0);
  //const [customerInfo, setCustomerInfo] = React.useState({});
  const [customerInfo, setCustomerInfo] = React.useState({});
  const navigate = useNavigate();
  const [isNextActive, setisNextActive] = React.useState(false);

  const steps =searchParams.get("applicant")?['Applicant', 'Personal', 'Address', 'Family', 'Bank', 'Documents', 'Review'] :
  ['Applicant', 'Personal', 'Address', 'Family', 'Bank', 'Reference', 'Documents', 'Review'];
  React.useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login')
    }
  }, [])
  React.useEffect(() => {
    id > 0 && services.getService(`customer/details/${id}`)
      .then((res) => {
        let data = res.data;
        
        services.getService(`loan/list?customer_id=${id}`)
          .then(res => setCustomerInfo({
            ...customerInfo, ...data, loan_details: res.data
          }))
          .catch(err => {
            if (!localStorage.getItem('token')) {
              navigate('/login');
            }
          })
      })
      .catch(err => {
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      })
  }, [])

  
  const goToStep = (activeStep) => {
    console.log(activeStep, steps.length, "activeStep")
    if (activeStep >= 0 && steps.length) {
      setActiveStep(activeStep);
    } else {
      console.log("Invalid step index")
    }
  }


  const handleNext = (val) => {
    setisNextActive(val);
    console.log(val, "value")
    goToStep(activeStep + 1);
  };

  const handleBack = () => {
    goToStep(activeStep - 1);
  };
  function getStepContent(activeStep) {
    let args = {
      goToStep,
      handleBack,
      handleNext,
      activeStep,
      steps,
      setCustomerInfo,
      customerInfo,
      id,
      isNextActive
    }
if(searchParams.get("applicant")){
  switch (activeStep) {
    case 0:
      return <CustomerAccountInfo {...args} />;
    case 1:
      return <CustomerPersonalInfo {...args} />;
    case 2:
      return <AddressDetails {...args} />;
    case 3:
      return <FamilyDetailsInfo {...args} />;
case 4:
      return <BankDetails {...args} />;
    case 6:
      return <Proofs {...args} />;
    case 7:
      return <Review {...args} />;
    default:
      throw new Error('Unknown step');
  }
}else{
  switch (activeStep) {
    case 0:
      return <CustomerAccountInfo {...args} />;
    case 1:
      return <CustomerPersonalInfo {...args} />;
    case 2:
      return <AddressDetails {...args} />;
    case 3:
      return <FamilyDetailsInfo {...args} />;
case 4:
      return <BankDetails {...args} />;
    case 5:
      return <GuarantorDetails {...args} />;
    case 6:
      return <Proofs {...args} />;
    case 7:
      return <Review {...args} />;
    default:
      throw new Error('Unknown step');
  }
}
   
  }
  let breadCrumbsData = []
 
    breadCrumbsData =props?.customer_type=="applicant" ?[{ name: `Applicants`, path: '/app/applicants' }, { name: id ? 'Edit' : 'Add', path: id ? `/app/applicant/${id}` : '/app/applicant/add' }]:
    [{ name: `Customers`, path: '/app/customers' }, { name: id ? 'Edit' : 'Add', path: id ? `/app/customers/${id}` : '/app/customers/add' }]

  



  return (
    <>
      <Helmet>
        <title>{id ? 'Edit' : 'Add'} Customer - Thangam Capital </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          {<BreadcrumbsComponent data={breadCrumbsData} />}
          <Box sx={{ pt: 3 }}>
            {isMobile === true ?

              <div className='container' style={{ display: 'flex', flexDirection: 'row' }}>
                <div ><span className='step-class'>1</span><span className='step-title'>Applicant</span></div>
                <div style={{ marginLeft: '6px' }}>-</div>
                <div style={{ marginLeft: '6px' }}><span className='step-class'>2</span><span className='step-title'>Personal</span></div>
                <div style={{ marginLeft: '6px' }}>-</div>
                <div style={{ marginLeft: '6px' }}><span className='step-class'>3</span><span className='step-title'>Address</span></div>
                <div style={{ marginLeft: '6px' }}>-</div>
                <div style={{ marginTop: '-16px', marginLeft: '6px' }}>

                  <MoreHorizIcon fontSize='large' style={{ color: "#4984BF", fontSize: '44px', marginTop: '4px' }} color='#4984BF' /></div>
              </div>
              : <Stepper activeStep={activeStep} sx={{
                "& .MuiStepLabel-root .Mui-completed": {
                  color: "green"
                },
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                  color: "orange"
                },
                "& .MuiStepLabel-root .Mui-active": {
                  color: "red"
                },
                "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                  fill: "white"
                },
                pt: 3,
                pb: 5
              }}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel onClick={() => goToStep(index)}>{label}</StepLabel>
                  </Step>

    //               

                ))}
                {isMobile && <div><MoreHorizIcon fontSize='large' style={{ color: "#4984BF", fontSize: '44px', marginTop: '4px' }} color='#4984BF' /></div>}
              </Stepper>}
            
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your order.
                  </Typography>
                  <Typography variant="subtitle1">
                    Your order number is #2001539. We have emailed your order
                    confirmation, and will send you an update when your order has
                    shipped.
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                </React.Fragment>
              )}
            </React.Fragment>
          </Box>
        </Container>
      </Box>
    </>
  );
}