import React, { useRef, useState } from "react";
import { Grid, Typography, Card, CardContent, Box, Stack, Button } from "@mui/material";
import { Collapse, Divider, Image, Modal, notification, Spin, Tabs, theme } from "antd";
import _ from "lodash";
import { Fieldset } from "../shared/Fieldset";
import { useSelector } from "react-redux";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { services } from "../services/services";
import moment, { duration } from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CommonApplicantDetails from "./common-applicant-details";
import axios from "axios";
import { useNavigate } from "react-router";

const LoanReviewScreen = ({ data, loanInfo, goToStep, activeStep, handleBack, customerDetail, ApplicantName }) => {
  const globalConfiguration = useSelector((state) => state?.globalConfiguration?.data);
  const navigate = useNavigate()
  const [loading, setloading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  // const [api, contextHolder] = notification.useNotification();
  const [imageUrl, setimageUrl] = useState("");
  

  if (_.isEmpty(loanInfo)) {
    // loanInfo=storeDetails
  }

  const ViewImage = async (value) => {
    try {
      let response = await services.getService(`file/get?key=${value}`);
      

      if (response?.presignedUrl) {
        setimageUrl(response?.presignedUrl);
        setPreviewVisible(true);
      } else {
        console.error("Missing presignedUrl or key", response);
      }
    } catch (err) {
      let errors = err?.data?.results;
      if (errors) {
      }
      throw err;
    }
  };
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type == "error" ? "Error" : "Success",
      description: type == "error" ? "Something went wrong" : "Loan created successfully",
      // duration:10
    });
  };
  const onSubmit = (data) => {
    // openNotificationWithIcon("success");
    //  navigate("/app/loans")
    
    setloading(true);
    services
      .postService(`loan/apply`, data)
      .then((res) => {
        setloading(false);
        openNotificationWithIcon("success");
        navigate("/app/loans")
      
      })
      .catch((err) => {
        setloading(false);
        openNotificationWithIcon("error");
        if (!localStorage.getItem("token")) {
          openNotificationWithIcon("error");
        }
      });
  };

  const filterLoanProduct = (loanType) => {
    let data = _.map(_.get(globalConfiguration, "loanProducts"), (value, key) => ({ value: key, label: value }));
    let product = _.filter(data, (value, index) => value?.value == loanType);
    return _.size(product) > 0 ? _.get(_.head(product), "label") : "Loan Product";
  };
  const handlePreview = () => {
    setPreviewVisible(true); // Open the modal with the preview
  };

  const handleCancel = () => {
    setPreviewVisible(false); // Close the modal
  };
  // Render Product
  const renderProduct = (data) => {
    

    return (
      <Box>
        <Grid container spacing={"1px"} padding={"0px"}>
          <Grid item xs={12} sm={6} md={3}>
            <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{ marginRight: "16px", marginBottom: "16px" }}>
              <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>Product</Typography>
              <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>{filterLoanProduct(data?.product)}</Typography>
            </Box>
          </Grid>
          {_.map(data?.productSpecificDetails, (value, key) => {
            

            return (
              (value != undefined || value != null) &&
              value && (
                <Grid item xs={12} key={key} sm={6} md={3} marginBottom={"12px"}>
                  {" "}
                  <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{}}>
                    <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>{_.startCase(key)}</Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>
                      {key == "loanPurpose" || key == "ownershipType"
                        ? _.startCase(value)
                        : key == "landValue"
                        ? `₹${formatIndianNumber(parseFloat(value))}`
                        : value}
                    </Typography>
                  </Box>
                </Grid>
              )
            );
          })}
        </Grid>
      </Box>
    );
  };

  //Render Invome and expense
  const renderIncome = (title, data) => {
    let incomeDetails = title == "Applicant" ? data?.income?.applicant : data?.income?.coApplicant;

    return (
      <Box>
        <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000", textDecorationLine: "underline", marginBottom: "15px" }}>
          Income
        </Typography>
        <Grid container spacing={2}>
          {_.map(incomeDetails, (value, key) => {
            return (
              key != "totalIncome" && (
                <Grid item xs={12} key={key} sm={6} md={2}>
                  {" "}
                  <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{ marginRight: "16px", marginBottom: "16px" }}>
                    <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>{_.startCase(key)}</Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>₹{value}</Typography>
                  </Box>
                </Grid>
              )
            );
          })}
        </Grid>
      </Box>
    );
  };
  //Render Expenses
  const renderExpenses = (title, data) => {
    const totalExpenses = !_.isEmpty(data)
      ? Object.values(title == "Applicant" ? data?.expenses?.applicant : data?.expenses?.coApplicant).reduce(
          (total, expense) => total + parseInt(expense || 0, 10),
          0
        )
      : 0;
    return (
      <Box>
        <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000", textDecorationLine: "underline", marginBottom: "15px" }}>
          Expenses
        </Typography>
        <Grid container spacing={2}>
          {_.map(title == "Applicant" ? data?.expenses?.applicant : data?.expenses?.coApplicant, (value, key, index) => (
            <Grid item xs={12} sm={6} md={2} key={index} sx={{}}>
              <Box display="flex" alignItems="flex-start" flexDirection="column" sx={{ padding: "8px" }}>
                <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>{_.startCase(key)}</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#000000" }}>₹{value}</Typography>
              </Box>
            </Grid>
          ))}
          <Divider style={{ marginBottom: "0px", marginTop: "12px" }} />
        </Grid>
        <Box display="flex" flexDirection={"column"} sx={{ marginRight: "32px", marginBottom: "16px" }}>
          <Grid item xs={12} sm={6} md={2}>
            <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>Total Income</Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>₹{totalExpenses}</Typography>
          </Grid>
        </Box>
      </Box>
    );
  };
  const arrangeObject = (asset) => {
    // const commonFilterFields = (fields)=>{
    //   _.filter
    // }
    let orderedFields;

    // Determine field order based on asset type or assetType
    if (asset?.assetType === "gold") {
      orderedFields = ["assetCategory", "assetType", "assetMetadata.weightInGrams", "assetValue"];
    } else if (asset?.assetType === "bank_fixed_deposit") {
      orderedFields = ["assetCategory", "assetType", "assetMetadata.bankName", "assetMetadata.depositAmount"];
    } else if (["life_insurance"].includes(asset?.assetType)) {
      orderedFields = ["assetCategory", "assetType", "assetMetadata.policyNumber"];
    } else if (["four_wheeler", "machinery", "two_wheeler", "commercial_vehicle", "car"].includes(asset?.assetType)) {
      orderedFields = ["assetCategory", "assetType", "assetMetadata.vehicleNumber", "assetValue"];
    } else if (asset?.assetCategory === "immovable") {
      orderedFields = ["assetCategory", "assetType", "assetMetadata.sizeInSqFeet", "assetValue"];
    } else {
      // Default order if no conditions match
      orderedFields = Object.keys(asset);
    }
    const result = _.map(orderedFields, (key) => {
      const value = _.get(asset, key); // Use _.get to handle nested properties
      return { [key.split(".").pop()]: value }; // Flatten the key if nested
    }).reduce((acc, item) => Object.assign(acc, item), {});

    return result;
  };
  const renderCommonFields1 = (v, i, item) => {
    

    return (
      i != "assetMetadata" &&
      v && (
        <Box
          key={i}
          sx={{
            // display: 'flex',
            alignItems: "center",
            flexDirection: "column",
            //   border:`1px solid #eaecf0`,
            borderRadius: "8px",
            padding: "3px",
            position: "relative",
            //   width: '20%',
            marginRight: "12px",
            width: "18%",
            //   marginBottom:'14px'
          }}
        >
          <Typography
            sx={{
              color: "#172b4d",
              fontSize: "15px",
              fontWeight: "400",
            }}
          >
            {i == "typeOfAsset" || i == "assetType" ? _.startCase(v) : i == "grms" ? `${v} grams` : i == "sqFt" ? `${v} Sq.Ft` : v}
          </Typography>
        </Box>
      )
    );
  };
  //render Asset Details

  const renderCardAsset = (data) => {
    return _.map(data, (item, index) => {
      return (
        <Card
          key={index}
          sx={{
            border: "1px solid #eaecf0",
            // borderRadius: "12px",
            // boxShadow: "0 4px 8px #0000001a",
            backgroundColor: index % 2 === 0 ? "#FFF" : "rgba(0, 0, 0, 0.04)",
            // marginTop: "12px",
            paddingBottom: "0px",
            // borderBottom:"none",
            // borderBottomWidth:"0px",
            // border:"none !important",
            boxShadow: "none !important",
            // marginLeft: "16px",
            // marginRight: "16px",
          }}
        >
          <CardContent
            sx={{
              paddingBottom: "0px !important",
              marginLeft: "12px",
              marginBottom: "12px",
              border: "none",
            }}
          >
            <div style={{ display: "flex", width: "100%" }}>
              <Box sx={{ width: "90%", display: "flex" }}>
                <Grid container item spacing={2} mb={"0px"} mt={0} sx={{ display: "flex", flexDirection: "row" }}>
                  {_.map(arrangeObject(item), (v, i) => {
                    return i != "assetValue" && i != "depositAmount" && renderCommonFields1(v, i, item);
                  })}
                </Grid>
                <Box>
                  <Grid container item spacing={2} mb={"0px"} mt={0} sx={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      sx={{
                        borderRadius: "8px",
                        padding: "3px",
                        position: "relative",
                        marginRight: "12px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgb(52, 64, 84)",
                          fontSize: "15px",
                          fontWeight: "400",
                        }}
                      >
                        ₹
                        {item?.assetType == "life_insurance"
                          ? formatIndianNumber(parseFloat(item?.assetMetadata?.premium))
                          : item?.assetMetadata?.depositAmount
                          ? formatIndianNumber(parseFloat(item?.assetMetadata?.depositAmount))
                          : formatIndianNumber(parseFloat(item?.assetValue))}
                      </Typography>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </div>
          </CardContent>
        </Card>
      );
    });
  };
  const renderAssetDetails = (data) => {
    let moveableList = _.filter(data?.assetDetails, (v, i) => v?.assetCategory == "movable");
    let imMoveableList = _.filter(data?.assetDetails, (v, i) => v?.assetCategory != "movable");
    return (
      <div>
        {_.size(moveableList) > 0 && (
          <Fieldset
            title={
              <Stack direction="row" alignItems="center" gap={1} sx={{ color: "#6b778c" }}>
                Movable
              </Stack>
            }
            color="#c8cdd5"
            titleSize="16px"
            borderWidth={1.5}
            borderRadius={1}
            margin="0px"
          >
            <Box sx={{ padding: "20px" }}>{renderCardAsset(moveableList)}</Box>
          </Fieldset>
        )}
        {_.size(imMoveableList) > 0 && (
          <Fieldset
            title={
              <Stack direction="row" alignItems="center" gap={1} sx={{ color: "#6b778c" }}>
                Immovable
              </Stack>
            }
            color="#c8cdd5"
            titleSize="16px"
            borderWidth={1.5}
            borderRadius={1}
            margin="0px"
            marginTop="40px"
          >
            <Box sx={{ padding: "20px" }}>{renderCardAsset(imMoveableList)}</Box>
          </Fieldset>
        )}
      </div>
    );
  };

  //render Document
  const renderDocument = (data) => {
    return (
      <>
        {_.map(data?.documents, (item, index) => {
          delete item?.documentUpload;
          return (
            <Grid container key={index} spacing={2}>
              {_.map(item, (v, i, index) => {
                return (
                  <Grid item key={i} sm={6} md={3}>
                    {" "}
                    <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{ marginRight: "16px", marginBottom: "16px" }}>
                      <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>
                        {i == "fileLocation" ? "Attachment" : _.startCase(i)}
                      </Typography>
                      {i == "fileLocation" ? (
                        <Typography
                          component={"span"}
                          onClick={() => {
                            ViewImage(v);
                          }}
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#00000",
                            boxShadow: "0 4px 8px #0000001a",
                            border: "1px solid #eaecf0",
                            padding: "2px 12px",
                            borderRadius: "7px",
                            cursor: "pointer",
                          }}
                        >
                          View
                        </Typography>
                      ) : (
                        <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>{i == "type" ? _.startCase(v) : v}</Typography>
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </>
    );
  };

  function formatIndianNumber(value) {
    if (!_.isNumber(value)) return value;

    const number = _.toString(value);
    const lastThree = number.slice(-3);
    const rest = number.slice(0, -3);

    return rest.length > 0 ? rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree : lastThree;
  }

  function calculateObjectValuesSum(obj) {
    return _.chain(obj)
      .values() // Get all values of the object
      .map((value) => parseFloat(value) || 0) // Convert each value to a float, default to 0 if not a number
      .sum() // Sum all the values
      .value(); // Extract the result
  }
  const renderIncomeExpenseTable = (type, loan) => {
    return type == "income" ? (
      <div style={{ display: "flex", flexDirection: "column", width: "70%", marginBottom: "50px" }}>
        {/* Header Row */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            backgroundColor: "#e0e0e0",
          }}
        >
          <div style={{ fontWeight: "bold", minWidth: "40%", textAlign: "left" }}>Income</div>
          <div style={{ flexBasis: "35%", textAlign: "left" }}>Applicant</div>
          <div style={{ flexBasis: "35%", textAlign: "left" }}>Co-Applicant</div>
        </div>
        {/* Data Rows */}
        {_.entries(loan?.income?.applicant).map(([key, value], index) => (
          <div
            key={key}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              backgroundColor: index % 2 === 0 ? "#FFF" : "rgba(0, 0, 0, 0.04)",
            }}
          >
            <div style={{ minWidth: "40%", textAlign: "left" }}>{_.startCase(key)}</div>
            <div style={{ flexBasis: "35%", textAlign: "left" }}>₹{formatIndianNumber(value)}</div>
            <div style={{ flexBasis: "35%", textAlign: "left" }}>₹{formatIndianNumber(loan?.income?.coApplicant[key])}</div>
          </div>
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            backgroundColor: "#FFF",
            borderTop: "1px solid #0e133b",
            borderBottom: "1px solid #0e133b",
          }}
        >
          <div style={{ fontWeight: "bold", minWidth: "40%", textAlign: "left" }}>Total Income</div>
          <div style={{ flexBasis: "35%", textAlign: "left" }}>₹{formatIndianNumber(calculateObjectValuesSum(loan?.income?.applicant))}</div>
          <div style={{ flexBasis: "35%", textAlign: "left" }}>₹{formatIndianNumber(calculateObjectValuesSum(loan?.income?.coApplicant))}</div>
        </div>
      </div>
    ) : (
      <div style={{ display: "flex", flexDirection: "column", width: "70%" }}>
        {/* Header Row */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            backgroundColor: "#e0e0e0",
          }}
        >
          <div style={{ fontWeight: "bold", minWidth: "40%", textAlign: "left" }}>Expenses</div>
          <div style={{ flexBasis: "35%", textAlign: "left" }}>Applicant</div>
          <div style={{ flexBasis: "35%", textAlign: "left" }}>Co-Applicant</div>
        </div>
        {/* Data Rows */}
        {_.entries(loan?.expenses?.applicant).map(([key, value], index) => (
          <div
            key={key}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              backgroundColor: index % 2 === 0 ? "#FFF" : "rgba(0, 0, 0, 0.04)",
            }}
          >
            <div style={{ minWidth: "40%", textAlign: "left" }}>{_.startCase(key)}</div>
            <div style={{ flexBasis: "35%", textAlign: "left" }}>₹{formatIndianNumber(value)}</div>
            <div style={{ flexBasis: "35%", textAlign: "left" }}>₹{formatIndianNumber(loan?.expenses?.coApplicant[key])}</div>
          </div>
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            backgroundColor: "#FFF",
            borderTop: "1px solid #0e133b",
            borderBottom: "1px solid #0e133b",
          }}
        >
          <div style={{ fontWeight: "bold", minWidth: "40%", textAlign: "left" }}>Total Expenses</div>
          <div style={{ flexBasis: "35%", textAlign: "left" }}>₹{formatIndianNumber(calculateObjectValuesSum(loan?.expenses?.applicant))}</div>
          <div style={{ flexBasis: "35%", textAlign: "left" }}>₹{formatIndianNumber(calculateObjectValuesSum(loan?.expenses?.coApplicant))}</div>
        </div>
      </div>
    );
  };

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Product</span> - <span>{filterLoanProduct(loanInfo?.product)}</span>{" "}
          <span
            style={{ width: "24px", height: "24px", marginLeft: "16px", cursor: "pointer" }}
            onClick={() => {
              goToStep(0);
            }}
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      ),
      children: renderProduct(loanInfo),
      style: panelStyle,
    },
    {
      key: "2",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Income & expenses</span>{" "}
          <span
            style={{ width: "24px", height: "24px", marginLeft: "16px", cursor: "pointer" }}
            onClick={() => {
              goToStep(1);
            }}
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      ),
      children: (
        <div>
          <div>
            {renderIncomeExpenseTable("income", loanInfo)}
            {renderIncomeExpenseTable("expense", loanInfo)}
          </div>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Assets details</span>{" "}
          <span
            style={{ width: "24px", height: "24px", marginLeft: "16px", cursor: "pointer" }}
            onClick={() => {
              goToStep(2);
            }}
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      ),
      children: renderAssetDetails(loanInfo),
      style: panelStyle,
    },
    {
      key: "4",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Documents</span>{" "}
          <span
            style={{ width: "24px", height: "24px", marginLeft: "16px", cursor: "pointer" }}
            onClick={() => {
              goToStep(3);
            }}
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      ),
      children: renderDocument(loanInfo),
      style: panelStyle,
    },
  ];
  const onChange = (key) => {};
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const filterCoApplicant = (id) => {
    if (_.size(_.get(customerDetail, "coApplicants")) > 0) {
      let coApplicants = _.filter(_.get(customerDetail, "coApplicants"), (value, index) => value?.customerId == id);
      return {
        coApplicantName: _.size(coApplicants) > 0 ? _.startCase(coApplicants[0].firstName + " " + coApplicants[0].lastName) : "-",
        coApplicantId: coApplicants[0]?.customerId,
        coApplicantNumber: coApplicants[0]?.mobileNumber,
      };
    } else {
      return null;
    }
  };
  const convertAdharSpace = (number) => {
    const result = _.chunk(number.toString(), 4)
      .map((chunk) => chunk.join("")) // Join each chunk back into a string
      .join(" ");

    return result;
  };

  return (
    <Spin spinning={loading}>
      {/* {contextHolder} */}
      <Card
        sx={{
          background: "#fff",
          border: "1px solid #eaecf0",
          borderBottom: "none !important",
          borderRadius: "8px",
          boxShadow: "0 1px 3px 0 #1018281a,0 1px 2px 0 #1018280f",
          marginBottom: "24px !important",
          padding: "0px",
        }}
      >
        <CardContent sx={{ padding: "0px" }}>
          <Modal open={previewVisible} footer={null} onCancel={handleCancel} centered>
            <Image
              preview={false}
              src={imageUrl} // Replace with your image URL
              alt="Preview"
            />
          </Modal>
          <Box sx={{ margin: "24px" }}>
            <CommonApplicantDetails
              applicantName={ApplicantName}
              applicantNumber={customerDetail?.mobileNumber}
              applicantId={customerDetail?.customerId}
              LoanName={filterLoanProduct(loanInfo?.product)}
              loanCode={loanInfo?.product}
              coApplicant={filterCoApplicant(loanInfo?.coApplicant)}
            />

            <Collapse
              defaultActiveKey={["1", "2", "3", "4"]}
              onChange={onChange}
              items={getItems(panelStyle)}
              style={{
                border: "none",
                background: "#fff",
              }}
            />

            <Box sx={{ display: "flex", justifyContent: "space-between", px: 2, pb: 2 }}>
              {" "}
              {activeStep !== 0 && (
                <Button variant="contained" onClick={() => handleBack()} sx={{ mt: 2, ml: 1 }}>
                  Previous
                </Button>
              )}
              <Button
                onClick={() => {
                  onSubmit(loanInfo);
                }}
                variant="contained"
                sx={{ mt: 2, ml: 1 }}
              >
                {"Apply"}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Spin>
  );
};

export default LoanReviewScreen;
